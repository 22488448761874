<template>
    <div id="box">
        <div class="box_one">
            <!-- 导航栏 -->
            <div class="nav">
                <!-- 电子围栏 -->
                <div class="nav_box flex">
                    <div style="width: 100%; text-align: center">电子围栏列表</div>
                </div>
            </div>
            <!-- 导航栏结束 -->
            <!-- 筛选 -->
            <div class="screen">
                <div class="screen_box flex">
                    <div>筛选 :</div>
                    <el-select v-model="screen" placeholder="全部" @change="changeScreen">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <!-- 筛选结束 -->
            <!-- 搜索 -->
            <div class="search flex">
                <div class="search_input flex">
                    <el-input v-model="search" placeholder="围栏名称"></el-input>
                    <!-- reserve-keyword
            <el-select style="width: 100%" v-model="search" placeholder="围栏名称" >
              <el-option v-for="item in optionsArray" :key="item.id" :label="item.label" :value="item.label">
              </el-option>
            </el-select> -->
                    <img class="mouse" src="@/assets/group/gb.png" @click="query(1)" alt="" />
                </div>
                <div class="search_but mouse" @click="query()">
                    <img src="@/assets/group/search.png" alt="" />
                </div>
            </div>

            <!-- 搜索结束 -->
            <!-- 树级控件 -->
            <div class="treeLevel" ref="treeLevel" :style="{ overflow: $store.state.prohibitRoll ? 'hidden' : 'auto' }">
                <div style="width:100%;">
                    <el-tree :data="enclosureArray" v-if="openPanel" :disabled="false" show-checkbox @check="check"
                        node-key="key" ref="tree" highlight-current :props="defaultProps"
                        @node-expand="handleNodeExpand" @node-collapse="collapse" :expand-on-click-node="false"
                        :default-expanded-keys="expandedKeys" :default-checked-keys="checkedArray">
                        <div class="treeLevel_box flex" slot-scope="{ node, data }" ref="element">
                            <el-popover popper-class="my_popover_one" placement="right-start" @show="onmouseover(data)"
                                @hide="onmouseout(data)" :open-delay="500" :offset="-5" :close-delay="200"
                                trigger="hover" :visible-arrow="false">
                                <div class="treeLevel_popup mouse prohibitCopy" v-if="data.children">
                                    <div class="treeLevel_popup_box" @click="selectBd(data, 1)">
                                        编辑
                                    </div>
                                    <div class="treeLevel_popup_box" @click="selectBd(data, 2)">
                                        修改区域
                                    </div>
                                    <div class="treeLevel_popup_box" @click="selectBd(data, 3)">
                                        删除
                                    </div>
                                </div>
                                <template slot="reference">
                                    <!-- margin_bottom -->
                                    <div :class="['treeLevel_tree', data.isShow ? 'flex_start' : '']">
                                        <div class="treeLevel_left flex">
                                            <!-- style="height: 16px;" -->
                                            <div v-show="!data.children" >
                                                <!-- 正在讲话 -->
                                                <template v-if="data.isStartSpeech">
                                                    <img class="treeLevel_right_img_one"
                                                        v-if="data.role == 0 && data.online == 3"
                                                        style="margin-right: 5px" src="@/assets/group/zzjhdyh.png"
                                                        alt="" />
                                                    <img class="treeLevel_right_img_one"
                                                        v-if="data.role == 3 && data.online == 3"
                                                        style="margin-right: 5px" src="@/assets/group/zzjh.png"
                                                        alt="" />
                                                </template>
                                                <template v-if="!data.isStartSpeech">
                                                    <!-- 摇闭开始 -->
                                                    <!-- 离线 -->
                                                    <!-- <img class="treeLevel_right_img_one" v-if="
                                                      data.role == 0 && data.online != 3 && !data.audioEnabled
                                                    " style="margin-right: 5px" src="@/assets/group/shakeCloseLx.png"
                                                        alt="" />
                                                    <img class="treeLevel_right_img_one" v-if="
                                                      data.role == 3 && data.online != 3 && !data.audioEnabled
                                                    " style="margin-right: 5px" src="@/assets/group/shakeCloseLx1.png"
                                                        alt="" /> -->
                                                    <!-- 在线 -->
                                                    <img class="treeLevel_right_img_one" v-if="
                                                        data.role == 0 &&
                                                        data.online == 3 &&
                                                        !data.isBeyond &&
                                                        !data.audioEnabled
                                                    " style="margin-right: 5px" src="@/assets/group/shakeCloseZx.png"
                                                        alt="" />
                                                    <img class="treeLevel_right_img_one" v-if="
                                                        data.role == 3 &&
                                                        data.online == 3 &&
                                                        !data.isBeyond &&
                                                        !data.audioEnabled
                                                    " style="margin-right: 5px" src="@/assets/group/shakeCloseZx1.png"
                                                        alt="" />
                                                    <!-- 在线不再组 -->
                                                    <img class="treeLevel_right_img_one" v-if="
                                                        data.role == 0 &&
                                                        data.online == 3 &&
                                                        data.isBeyond &&
                                                        !data.audioEnabled
                                                    " style="margin-right: 5px" src="@/assets/group/shakeCloseDdy.png"
                                                        alt="" />
                                                    <img class="treeLevel_right_img_one" v-if="
                                                        data.role == 3 &&
                                                        data.online == 3 &&
                                                        data.isBeyond &&
                                                        !data.audioEnabled
                                                    " style="margin-right: 5px" src="@/assets/group/shakeCloseDdy1.png"
                                                        alt="" />
                                                    <!-- 摇闭结束 -->
                                                    <img class="treeLevel_right_img_one" v-if="
                                                        data.role == 0 && data.online != 3
                                                        //    && data.audioEnabled
                                                    " style="margin-right: 5px" src="@/assets/group/off-lineUser.png"
                                                        alt="" />
                                                    <img class="treeLevel_right_img_one" v-if="
                                                        data.role == 3 && data.online != 3
                                                        //   && data.audioEnabled
                                                    " style="margin-right: 5px" src="@/assets/group/off-lineDdy.png"
                                                        alt="" />
                                                    <img class="treeLevel_right_img_one" v-if="
                                                        data.role == 0 &&
                                                        data.online == 3 &&
                                                        !data.isBeyond &&
                                                        data.audioEnabled
                                                    " style="margin-right: 5px" src="@/assets/group/xzUser.png"
                                                        alt="" />
                                                    <img class="treeLevel_right_img_one" v-if="
                                                        data.role == 0 &&
                                                        data.online == 3 &&
                                                        data.isBeyond &&
                                                        data.audioEnabled
                                                    " style="margin-right: 5px" src="@/assets/group/bzzUser.png"
                                                        alt="" />
                                                    <img class="treeLevel_right_img_one" v-if="
                                                        data.role == 3 &&
                                                        data.online == 3 &&
                                                        !data.isBeyond &&
                                                        data.audioEnabled
                                                    " style="margin-right: 5px" src="@/assets/group/zzDdy1.png"
                                                        alt="" />
                                                    <img class="treeLevel_right_img_one" v-if="
                                                        data.role == 3 &&
                                                        data.online == 3 &&
                                                        data.isBeyond &&
                                                        data.audioEnabled
                                                    " style="margin-right: 5px" src="@/assets/group/zzDdy.png"
                                                        alt="" />
                                                </template>
                                            </div>
                                            <!-- <img class="treeLevel_right_img_one" v-show="data.role == 3 && data.online == 1 && !data.Ingroup" style="margin-right:5px;" src="../assets/group/zzDdy.png" alt=""> -->
                                            <div class="treeLevel_left_label" @click.prevent="choiceName(data)"
                                                :class="data.children && choiceNameData.children && choiceNameData.efId === data.efId || !data.children && !choiceNameData.children && choiceNameData.User_ID == data.User_ID ? 'blue_one' : ''">
                                                <span v-html="eachColor(node.label, data.children ? search1 : '')">
                                                    {{ node.label }}
                                                </span>
                                                <div v-if="data.isShow">
                                                    <span style="color: var(--main-color); font-size: 12px">{{
                                                            data.effectiveTimeSlot[0]
                                                    }} ~
                                                        {{ data.effectiveTimeSlot[1] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="treeLevel_right" v-show="data.children">
                                            <div :class="[
                                                'treeLevel_right_but',
                                                data.isEffective ? 'blue' : 'grey',
                                            ]" style="margin-bottom: 5px">
                                                {{ data.isEffective ? '有效' : '失效' }}
                                            </div>
                                            <div class="treeLevel_right_but red">
                                                {{ data.efType == 0 ? "禁出" : "禁入" }}
                                            </div>
                                        </div>
                                        <div class="treeLevel_right flex" v-show="!data.children">
                                            <img v-if="data.isEffective && data.services && data.isBeyond"
                                                class="treeLevel_right_img_one" src="@/assets/group/Crosstheborder.png"
                                                alt="" />
                                        </div>
                                    </div>
                                </template>
                            </el-popover>
                        </div>
                    </el-tree>
                </div>
            </div>
            <!-- 树级控件 -->
            <div style="border-top: 1px solid #4f7777; padding: 10px 0px">
                <!-- 电子围栏 -->
                <div class="bottom flex">
                    <div class="bottom_box mouse" v-for="(item, index) in dzGroupArray" :key="item.id"
                        @click="groupMethod(item, index)" v-preventReClick >
                        <img :src="item.url" alt="" />
                        <div>{{ item.name }}</div>
                    </div>
                </div>
            </div>
        </div>
        <modify ref="modify" v-if="hackReset" @changeEditEnclosure="changeEditEnclosure"
            :editEnclosureInfo="editEnclosureInfo" :bgShow="true"></modify>
        <enclosure v-if="selectIndex == 4"></enclosure>
    </div>
</template>
  
<script>
import modify from "@/components/enclosure/modify.vue";
import enclosure from "@/components/enclosure/establishEnclosure.vue";
import { queryEnclosureMember } from "@/administration/electronicsEnclosure.js";
import { queryUserService } from "@/administration/dispatchLntercom.js";
import EventBus from "@/eventBus/eventBus.js";
import beasconfig from "@/api/beasconfig.js"
import { _throttle } from "@/method/utils.js";
const enclosureUrlapi = beasconfig.enclosureUrlapi;
// import moment from 'moment';
import axios from "axios";
// import qs from "qs";

export default {
    components: {
        modify,
        enclosure,
    },
    props: {

    },
    data() {
        return {
            navArray: [
                {
                    id: 0,
                    name: "全部群组",
                },
                {
                    id: 0,
                    name: "固定群组",
                },
                // {
                //   id: 0,
                //   name: "区域群组",
                // },
            ],
            navIndex: 0, //群组tab下标
            options: [
                {
                    //筛选类型
                    value: "-1",
                    label: "全部",
                },
                {
                    value: "3",
                    label: "有效",
                },
                {
                    value: "1",
                    label: "无效",
                },
            ],
            defaultProps: {
                children: "children",
                label: "label",
            },
            dzGroupArray: [
                //电子围栏群组底部方法
                {
                    id: 1,
                    name: "呼叫",
                    url: require("@/assets/group/group2.png"),
                },
                {
                    id: 2,
                    name: "创建围栏",
                    url: require("@/assets/group/group5.png"),
                },
                {
                    id: 3,
                    name: "越界记录",
                    url: require("@/assets/group/group8.png"),
                }
            ],
            search: "", //搜索
            search1: "",  //搜索选中
            screen: "",    //筛选类型
            optionsArray: [],
            expandedKeys: [],
            list: [],
            pdType: 0, //
            userInfo: {},
            userData: {}, //用户数据
            pageData: {
                pageNum: 0,
                pageSize: 0,
            },
            enclosureArray: [],
            enclosureArrayOne: [],
            selectIndex: 0,
            userArray: [],
            choiceNameData: "",
            editEnclosureInfo: {},    //编辑围栏信息
            hackReset: false,
            choiceData: [],    //选中数据
            checkedArray: [],     //选中
            openPanel: true,
            temporaryTime: null //计时器
        };
    },
    created() {
        let that = this;
        that.screenGroupUserData();
        EventBus.$on("emptyChoice", function () {        //清空选中
            that.choiceNameData = "";
        });
    },
    beforeDestroy: function () {
        this.clearTimer();
        EventBus.$off("emptyChoice");
    },
    mounted() {
    },
    filters: {

    },
    methods: {
        eachColor(content, searchVal) {
            let nt = '<span style="color:var(--main-color);">' + searchVal + "</span>";
            return content.replace(searchVal, nt) + "&nbsp;";
        },
        // 切换标签卡
        navTab(index) {
            this.navIndex = index;
        },
        refreshTree() {    //刷新树
            this.openPanel = false;
            this.$nextTick(() => {
                this.openPanel = true;
            })
        },
        changeScreen(e) {
            //筛选数据
            let enclosureArrayOne = JSON.parse(
                JSON.stringify(this.enclosureArrayOne)
            );
            let array = [];
            if (e == 3) {   //3有效   1无效
                array = enclosureArrayOne.filter(item => item.isEffective);
            } else if (e == 1) {
                array = enclosureArrayOne.filter(item => item.isEffective == false);
            } else {
                array = enclosureArrayOne;
            }
            this.enclosureArray = array;
            this.search = "";
            this.search1 = "";
            this.screen = e;
            this.expandedKeys = [];
            this.checkedArray = []; //清空选中的值
        },
        electronicFenceList() {
            let that = this;
            that.clearTimer();      //先清除定时器
            let params = {
                companyId: parseInt(that.userInfo.User_CompanyID),
                ddyId: parseInt(that.userInfo.User_ID),
                pageNum: that.pageData.pageNum,
                pageSize: that.pageData.pageSize, //20个围栏
                // efName: that.search,
                // efType: this.eftype,
                // startTime: this.startTime,
                // endTime: this.endTime
            };
            axios.get(`${enclosureUrlapi}-${that.$store.state.userState.server}/electronicFence/list`, { params }).then((res) => {
                // console.log("围栏列表", res);
                if (res.data.data.rows) {
                    let array = res.data.data.rows;
                    that.getQueryUserByEnclosure(JSON.stringify(array));
                    that.startTime();
                }
            }).catch(console.error)
        },
        choiceName(data) {
            //选中数据
            if (!data.User_ID && this.choiceNameData.efId == data.efId && !this.choiceNameData.User_ID || data.User_ID && this.choiceNameData.User_ID == data.User_ID) {
                this.choiceNameData = "";
                this.$store.state.selectedInfo = [];
                EventBus.$emit("changeMapTow", "", 0);
            } else {
                this.choiceNameData = data;
                this.$store.state.selectedInfo = [data];
                let type = data.children ? 2 : 1;
                EventBus.$emit("changeMapTow", data, type);
            }
        },
        getQueryUserByEnclosure(arr) {
            //查询围栏成员
            let that = this;
            let array = JSON.parse(arr);
            let enclosure = [];
            into(0)
            function into(n) {
                if (n < array.length) {
                    array[n].label = array[n].efName;
                    let startTime = that.$moment(new Date()).format("YYYY-MM-DD");
                    let endTime = that.$moment(array[n].validEndTime).format("YYYY-MM-DD");
                    array[n].isEffective = startTime > endTime && array[n].validEndTime != 0 ? false : true;
                    array[n].effectiveTimeSlot = array[n].effectiveTimes.split("|");
                    // console.log(array[n].effectiveTimes.split("|"))
                    array[n].isShow = true; //父
                    array[n].isBool = false;
                    array[n].children = [];
                    array[n].key = array[n].efId;
                    queryEnclosureMember({
                        enclosureId: parseInt(array[n].efId),
                    }).then((res) => {
                        let row = res.data.data;
                        let userData = [];
                        if (res.data.code == 200) {
                            row.forEach(item => {
                                item.efId = array[n].efId;
                                item.isEffective = array[n].isEffective;
                                let userInfoData = that.userArray.find(one => item.User_ID == one.id);
                                if (userInfoData && userInfoData.beyondList && userInfoData.beyondList.length > 0) {
                                    let bool = userInfoData.beyondList.some(beyond => beyond == item.efId);        //判断当前用户是否是越界围栏
                                    userInfoData.isBeyond = bool;
                                }
                                if (userInfoData) {
                                    if (userInfoData.online != 3) {
                                        userInfoData.key = array[n].efId + "" + item.User_ID;
                                        userData.push({ ...item, ...userInfoData })
                                    } else {
                                        userInfoData.key = array[n].efId + "" + item.User_ID;
                                        userData.unshift({ ...item, ...userInfoData })
                                    }
                                } else if (that.$store.state.soketUserInfo.uid != item.User_ID) {
                                    item.disabled = true;
                                    item.online = 1;
                                    item.role = 0;
                                    item.label = item.User_Name;
                                    item.key = array[n].efId + "" + item.User_ID;
                                    item.id = item.User_ID;
                                    userData.push({ ...item })
                                }
                                // if (that.$store.state.soketUserInfo.uid == item.User_ID) {
                                //     item.id = that.$store.state.soketUserInfo.uid;
                                //     userData.unshift({ ...item, ...that.$store.state.soketUserInfo, ...{ key: array[n].efId + "" + that.$store.state.soketUserInfo.uid } })
                                // }
                            })
                            array[n].children = userData;
                            enclosure.push(array[n]);
                            into(n + 1);
                        }
                    });
                } else {
                    that.enclosureArrayOne = enclosure;
                    if (that.screen == 3) {   //3有效   1无效
                        enclosure = enclosure.filter(item => item.isEffective);
                    } else if (that.screen == 1) {
                        enclosure = enclosure.filter(item => item.isEffective == false);
                    }
                    that.enclosureArray = enclosure;
                    if (that.checkedArray.length > 0) {
                        that.$refs.tree.setCheckedKeys(that.checkedArray);
                    }

                }
            }
        },
        changeEditEnclosure(data, type) {    //编辑改动
            let row = JSON.parse(data);
            this.getQueryUserByEnclosureOne(row, type)
            // this.screenGroupUserData
        },
        getQueryUserByEnclosureOne(row, type) {
            let that = this;
            let index = that.enclosureArray.findIndex(item => item.efId === row.efId);
            if (type == 1) {
                that.enclosureArray[index].label = row.efName;
                that.enclosureArray[index].effectiveTimeSlot = row.effectiveTimes.split("|");
                let startTime = that.$moment(new Date()).format("YYYY-MM-DD");
                let endTime = that.$moment(row.validEndTime).format("YYYY-MM-DD");
                that.enclosureArray[index].isEffective = startTime > endTime && row.validEndTime != 0 ? false : true;
                that.enclosureArray[index].efType = Number(row.efType);
            }
            queryEnclosureMember({
                enclosureId: parseInt(row.efId),
            }).then((res) => {
                let row = res.data.data;
                let userData = [];
                if (res.data.code == 200) {
                    row.forEach(item => {
                        item.efId = that.enclosureArray[index].efId;
                        item.isEffective = that.enclosureArray[index].isEffective;
                        let userInfoData = that.userArray.find(one => item.User_ID == one.id);
                        if (userInfoData) {
                            if (userInfoData.online != 3) {
                                userInfoData.key = that.enclosureArray[index].efId + "" + item.User_ID;
                                userData.push({ ...item, ...userInfoData })
                            } else {
                                userInfoData.key = that.enclosureArray[index].efId + "" + item.User_ID;
                                userData.unshift({ ...item, ...userInfoData })
                            }
                        } else if (that.$store.state.soketUserInfo.uid != item.User_ID) {
                            item.disabled = true;
                            item.online = 1;
                            item.role = 0;
                            item.label = item.User_Name;
                            item.key = that.enclosureArray[index].efId + "" + item.User_ID;
                            item.id = item.User_ID;
                            userData.push({ ...item })
                        }
                        // that.userArray.filter(item1 => {
                        //     if (item.User_ID == item1.id) {
                        //         //  userData.push({ ...item, ...item1 })
                        //         if (item1.online != 3) {
                        //             item1.key = that.enclosureArray[index].efId + "" + item.User_ID
                        //             userData.push({ ...item, ...item1 })
                        //         } else {
                        //             item1.key = that.enclosureArray[index].efId + "" + item.User_ID
                        //             userData.unshift({ ...item, ...item1 })
                        //         }
                        //     }
                        // })
                        // if (that.$store.state.soketUserInfo.uid == item.User_ID) {
                        //     // userData.unshift({ ...item, ...that.$store.state.soketUserInfo })
                        //     item.id = that.$store.state.soketUserInfo.uid;
                        //     userData.unshift({ ...item, ...that.$store.state.soketUserInfo, ...{ key: that.enclosureArray[index].efId + "" + that.$store.state.soketUserInfo.uid } })
                        // }
                    })
                    that.$set(that.enclosureArray[index], 'children', userData);
                    that.enclosureArrayOne = that.enclosureArray;
                    setTimeout(() => {
                        that.handleNodeExpand(that.enclosureArray[index]);
                    }, 500)
                }
            });
        },
        groupMethod(item) {
            //左侧切换内容
            if (item.id == 1) {   //呼叫
                if (this.$store.state.audio_enable != 1) {
                    this.$MessageWarning(`摇毙中，不可创建`);
                    return
                }
                if (this.choiceData.length == 0) {
                    this.$MessageWarning(`请选择需要${item.name}的用户！`);
                    return;
                }
                let choiceData = this.choiceData;
                let array = this.deweight(choiceData);
                let index = array.findIndex(item => item.User_ID == this.$store.state.soketUserInfo.uid);
                this.checkedArray = [];
                this.choiceData = [];
                this.$refs.tree.setCheckedKeys(this.checkedArray);
                if (index >= 0 && array.length == 1) {        //如果选中的只有自己单呼失败
                    this.$MessageWarning(`单呼失败`);
                    return
                }
                if (index >= 0 && array.length > 1) {       //如果选中的不止自己就过滤自己
                    array.splice(index, 1);
                }
                let obj = {
                    ids: array.map(item => item.id),
                    row: array
                };
                this.refreshTree();
                EventBus.$emit("menuOption", 2, obj);    //2是创建临时群组
            }
            if (item.id == 2) {   //创建围栏
                this.$customMessageError(`请先搜索或自圈选围栏区域！`);
                this.choiceNameData = "";
                this.$store.state.isMappingTool = true;
                EventBus.$emit("emptyMap", 0);    //2是创建临时群组
                return
            }
            if (item.id == 3) {   //越界记录
                // this.choiceNameData = "";
                this.$router.push('/enclosure/TransboundaryRecord')
            }
        },
        query(type) {
            //查询方法
            //筛选数据
            let enclosureArrayOne = JSON.parse(
                JSON.stringify(this.enclosureArrayOne)
            );
            if (type == 1) {
                this.search = "";
                // this.expandedKeys = [];
                this.search1 = this.search;
                return
            }
            this.search1 = this.search;
            this.checkedArray = []; //清空选中的值
            if (this.search.trim() != '' && this.enclosureArray.length > 0) {
                let array = [];
                if (this.screen == 3) {   //3有效   1无效
                    array = enclosureArrayOne.filter(item => item.isEffective);
                } else if (this.screen == 1) {
                    array = enclosureArrayOne.filter(item => item.isEffective == false);
                } else {
                    array = enclosureArrayOne;
                }
                this.enclosureArray = array;
                // this.enclosureArray.forEach(item => {
                //     if (item.label.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
                //         this.expandedKeys.push(item.key)
                //     }
                // })
            }
            // else {
            //     this.enclosureArray = enclosureArrayOne;
            //     // this.expandedKeys = [];
            // }
        },
        check(row, tree) {
            //选中树级菜单的值
            this.choiceData = tree.checkedNodes;
            this.checkedArray = tree.checkedNodes
                .filter(item => item.online == 3)
                .map(item => item.key);
        },
        //
        async handleNodeExpand(row) {
            //展开调用数据
            let i = this.enclosureArray.findIndex((item) => item.efId == row.efId);
            let array = row.children.map((item) => item.User_ID).join(); //集合id
            // let children = [];
            // 获取成员服务状态
            this.expandedKeys.push(row.key);
            this.expandedKeys = this.deweightOne(this.expandedKeys);

            await queryUserService({
                uId: this.userInfo.id,
                uids: array
            }).then((res) => {
                res.data.data.forEach((item) => {
                    row.children.forEach((item1, index) => {
                        if (item.User_ID == item1.User_ID) {
                            if (item.beyondList && item.beyondList.length > 0) {
                                let bool = item.beyondList.some(item => item == item1.efId);        //判断当前用户是否是越界围栏
                                item.isBeyond = bool;
                            }
                            this.$set(this.enclosureArray[i].children, index, {
                                ...item1,
                                ...item
                            });
                        }
                    });
                });
            });
            this.enclosureArrayOne = this.enclosureArray;
            await this.$refs.tree.setCheckedKeys(this.checkedArray);
        },
        collapse(row, tree) {
            //收起
            let index = this.expandedKeys.findIndex(item => item == tree.data.key);
            this.expandedKeys.splice(index, 1);
        },
        screenGroupUserData: _throttle(function () {        //筛选数据
            let that = this;
            that.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
            // let groupUserData = JSON.parse(sessionStorage.getItem("groupArray"));
            // let array = [];
            // groupUserData.forEach(item => {
            //     array.push(...item.children);
            // })
            // this.userArray = this.deweight(array);
            that.userArray.forEach(item => {
                let i = that.choiceData.findIndex(row => row.id == item.id);
                if (item.online != 3 && i >= 0) {
                    that.choiceData.splice(i, 1);
                }
            })
            if (that.$store.state.soketUserInfo.uid) {
                that.userArray.push({ id: that.$store.state.soketUserInfo.uid, ...that.$store.state.soketUserInfo })
            }
            let checkedArray = that.choiceData.map(item => item.key);
            that.checkedArray = checkedArray;
            let ids = that.userArray.map(item => item.id).join();
            queryUser();
            async function queryUser() {
                await queryUserService({
                    uId: that.userInfo.id,
                    uids: ids
                }).then((res) => {
                    res.data.data.forEach((item) => {
                        that.userArray.forEach((item1, index) => {
                            if (item.User_ID == item1.id) {
                                if (!item.beyondList) {
                                    item.beyondList = [];
                                    item.isBeyond = false;
                                }
                                that.userArray[index] = { ...item1, ...item };
                            }
                        });
                    });
                });
                that.electronicFenceList();
            }
        }, 1000),
        getUserInfo: _throttle(function () {  //更新用户信息
            let that = this;
            into(0)
            async function into(f) {
                let array = that.$store.state.enclosureTc;
                if (f < array.length) {
                    let i = that.enclosureArray.findIndex((item) => item.efId == array[f].WLID);
                    let uids = that.enclosureArray[i].children.map((item) => item.User_ID).join(); //集合id
                    // 获取成员服务状态
                    await queryUserService({
                        uId: that.userInfo.id,
                        uids: uids
                    }).then((res) => {
                        res.data.data.forEach((item) => {
                            that.enclosureArray[i].children.forEach((item1, index) => {
                                if (item.User_ID == item1.User_ID) {
                                    if (item.beyondList && item.beyondList.length > 0) {
                                        let bool = item.beyondList.some(item => item == item1.efId);        //判断当前用户是否是越界围栏
                                        item.isBeyond = bool;
                                    }
                                    that.$set(that.enclosureArray[i].children, index, {
                                        ...item1,
                                        ...item
                                    });
                                }
                            });
                        });
                        into(f + 1)
                    });
                    await that.$refs.tree.setCheckedKeys(that.checkedArray);
                }
                // else {
                //   console.log(that.checkedArray)
                //   // that.$refs.tree.setCheckedKeys(that.checkedArray);
                // }
            }
        }, 1000),
        onmouseover(data) {
            this.$refs.tree.setCurrentKey(data.key);
        },
        onmouseout() {
            //鼠标离开触发
            this.$refs.tree.setCurrentKey(null);
        },
        selectBd(data, type) {
            //选中
            if (type == 1) {
                this.selectIndex = type;
                this.hackReset = false;
                this.$nextTick(() => {
                    this.hackReset = true;
                    setTimeout(() => {
                        this.$refs.modify.openPg();
                    }, 200)
                })
                this.editEnclosureInfo = data;
                // EventBus.$emit("emptyMap", 1);
            } else if (type == 2) {    //编辑围栏
                this.choiceNameData = "";
                this.$store.state.isMappingTool = false;
                EventBus.$emit('editEnclosureMap', data, 1)
            } else if (type == 3) {
                this
                    .$confirm("确定要删除电子围栏吗?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    })
                    .then(() => {
                        this.deleteEnclosure(data);
                    })
                    .catch(() => { });
            }
        },
        deleteEnclosure(data) {  //删除围栏
            axios.delete(`${enclosureUrlapi}-${this.$store.state.userState.server}/electronicFence/del/${data.efId}`)
                .then(res => {
                    if (res.data.code == 200) {
                        let index = this.enclosureArray.findIndex(item => item.efId == data.efId);
                        this.enclosureArray.splice(index, 1);
                        if (this.choiceNameData != "") {
                            this.choiceNameData = "";
                        }
                        EventBus.$emit("emptyMap", 1);
                    } else {
                        this.$MessageWarning(res.data.msg);
                    }
                })
        },
        arrayMerge() {
            //数据合并
            let groupUserData = this.$store.state.groupUserData;
            let array = [];
            groupUserData.forEach((item) => {
                item.children.filter((itmeOne) => {
                    array.push(item, itmeOne);
                });
            });
            return this.deweight(array);
        },
        deweight(arr) {
            //数组去重
            let de_arr = [];
            arr.forEach((i) => {
                let isTrue = de_arr.every((j) => j.id != i.id);
                isTrue ? de_arr.push(i) : "";
            });
            return de_arr;
        },
        deweightOne(arr) {
            //数组去重
            let de_arr = [];
            arr.forEach((i) => {
                let isTrue = de_arr.every((j) => j != i);
                isTrue ? de_arr.push(i) : "";
            });
            return de_arr;
        },
        startTime() {
            //30s刷新地图
            //开始计时
            let that = this;
            that.clearTimer();
            function timerTow() {
                return setTimeout(() => {
                    that.screenGroupUserData();
                }, 30000);
            }
            that.temporaryTime = timerTow();
        },
        clearTimer() {  // 清除定时器
            if (this.temporaryTime != null) {
                clearTimeout(this.temporaryTime);
                this.temporaryTime = null;
            }
        }
    },
    watch: {
        //监听事件
        "$store.state.toUpdateEnclosure": {
            handler(type) {
                // console.log(type)
                if (type == 1) {    //1是创建围栏
                    this.electronicFenceList();
                } else if (type == 2) {
                    // this.getUserInfo();
                    this.screenGroupUserData();
                }
            },
            deep: true
        },
        "$store.state.groupOnline": {
            handler(array) {
                if (array && array.length > 0) {
                    this.userArray = array[0].onlineArray;
                    this.screenGroupUserData();
                }
            },
            immediate: true
        }
    }
};
</script>
  
<style scoped lang="less">
#box {
    width: 100%;
    height: 100%;
    background: url(../../assets/group/leftBg.png);
    background-size: 100% 100%;

    .box_one {
        width: calc(100% - 20px);
        height: calc(100vh - 80px - 20px);
        // background: url(../..//assets/group/leftBg.png);
        // background-size: 100% 100%;
        position: relative;
        top: 10px;
        left: 10px;
    }

    .treeLevel /deep/.el-tree {
        background-color: transparent;
        color: #fff;

        .is-expanded>.el-tree-node__content {
            // height: 45px !important;
            display: flex;
            align-items: baseline;
            margin: 0px;
            height: auto;
            padding: 5px 0;

            .el-tree-node__expand-icon {
                padding: 4px 6px;
            }
        }

        .el-tree-node__content {
            // height: 45px !important;
            height: auto;
            padding: 5px 0;
            display: flex;
            align-items: baseline;
            // align-items: self-start;
        }

        .el-tree-node__content:hover,
        .el-upload-list__item:hover {
            background-color: #343434 !important;
        }

        .el-tree-node:focus,
        .is-expanded:focus,
        .is-focusable:focus {
            background-color: #343434;
        }

        .el-tree-node.is-current>.el-tree-node__content {
            background-color: #343434;
        }

        .el-tree-node__expand-icon.expanded {
            color: var(--main-color);
        }

        .el-checkbox__inner {
            background-color: #343434;
        }

        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner,
        .el-checkbox__inner:hover {
            border-color: #dcdfe6;
        }

        .el-checkbox__inner::after {
            border-color: var(--main-color);
        }

        .el-tree-node:focus>.el-tree-node__content {
            background: transparent;
        }

        .el-tree-node>.el-tree-node__children {
            background-color: #404040;
            width: 280px;
        }

    }

    .nav {
        width: 100%;
        height: 35px;
        line-height: 35px;
        margin: 0px auto;
        font-size: 14px;
        color: #fff;
        padding-top: 10px;
        border-bottom: 1px solid #3b7777;

        &_box {
            width: 92%;
            margin: auto;
        }
    }

    .screen {
        width: 92%;
        margin: auto;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 15px 0px;

        .screen_box {
            flex: 1;

            /deep/.el-input__inner {
                width: 94px;
                height: 28px;
                line-height: 28px;
                margin-left: 10px;
                // padding: 0px 20px;
                border: 2px solid #3b7777;
                background-color: #4e5656;
                color: #ffffff !important;

                .el-input__inner {
                    line-height: 28px;
                }
            }

            /deep/.el-select__input {
                width: 150px !important;
            }
        }

        img {
            width: 31px;
            height: 22px;
        }
    }

    .treeLevel {
        width: calc(100%);
        height: calc(100vh - 80px - 270px);
        overflow: auto;
        padding: 15px 0px;

        &_box {
            width: 100%;

            span {
                width: 100%;
            }
        }

        &_tree {
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 15px;
            //   flex-direction: row;
        }

        &_left {
            flex: 1;
            text-align: left;

            &_label {
                width: 120px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &_yelow {
                color: #ffb400;
            }

            &_ls {
                color: #4eb4b4;
            }
        }

        &_right {
            font-size: 12px;
            color: #8a8a8a;
            margin-right: 8px;
            position: relative;

            &_but {
                padding: 0px 2px;
                border-radius: 2px;
                color: #000000;
                font-size: 12px;
            }

            // position: relative;
            &_jr {
                width: 50px;
                // padding: 1px 10px;
                border: 1px solid #8a8a8a;
                border-radius: 50px;
            }

            &_color {
                border: none;
                background-color: var(--main-color);
                color: #333333;
            }

            &_img {
                width: 16px;
                height: 16px;
                margin-left: 5px;
            }

            &_img_one {
                width: 16px;
                height: 16px;
                margin-left: 5px;
            }
        }

    }

    /deep/.el-input__icon {
        line-height: 28px !important;
    }

    /deep/.el-select .el-input .el-select__caret {
        color: var(--main-color) !important;
    }

    .search {
        width: 100%;
        height: 30px;
        background-color: #3b7777;
        border: 2px solid #3b7777;

        &_input {
            flex: 1;
            height: 100%;
            background-color: #333333;
            color: #ffffff !important;

            /deep/.el-input__inner {
                width: calc(100% - 42px);
                // width: 100%;
                height: 30px;
                line-height: 30px;
                background-color: #333333;
                color: #ffffff !important;
                border: none;
                border-radius: 0px;
                padding: 0px;
            }

            img {
                width: 12px;
                height: 12px;
                margin: 0px 15px;
            }
        }

        &_but {
            width: 40px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background-color: #3b7777;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 17px;
                height: 17px;
            }
        }
    }

    .bottom {
        width: 92%;
        // position: fixed;
        // bottom: 0px;
        // left: 4%;
        margin: auto;
        font-size: 14px;
        color: #fff;

        &_box {
            flex: 1;
            // width: 25%;
            text-align: center;

            img {
                width: 41px;
                height: 40px;
            }
        }
    }
}

.treeLevel_popup {
    width: 90px;
    overflow: hidden;
    background: url(../../assets/group/leftBg1.png);
    background-size: 100% 100%;
    position: relative;
    margin-left: -2px;
    color: #fff;
    transition: 0.1s;
    font-size: 12px;

    &_box {
        width: 90px;
        margin: auto;
        height: 25px;
        line-height: 25px;
        text-align: center;

        // margin-bottom: 5px;
        &:hover {
            background-color: #666666;
        }
    }

    &_ms {
        width: 90%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;

        &_num {
            width: 25px;
            height: 13px;
            line-height: 13px;
            text-align: center;
            border: 1px solid #666;
            color: #ffffff;
            margin-right: 5px;

            &_red {
                color: var(--main-color) !important;
                border: 1px solid var(--main-color) !important;
            }
        }
    }
}

.blue_one {
    color: var(--main-color);
}


.blue {
    background-color: var(--main-color);
}

.red {
    background-color: red;
}

.grey {
    background-color: #666666;
}

.margin_bottom {
    margin-bottom: 5px;
}

.flex {
    display: flex;
    align-items: center;
}

.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_start {
    display: flex;
    align-items: flex-start !important;
}

::-webkit-scrollbar {
    width: 0px;
    height: 100%;
    background-color: #ccc;
}

::-webkit-scrollbar-track {
    width: 0px;
    background-color: #4d4d4d;
}

::-webkit-scrollbar-thumb {
    background-color: #727171;
}
</style>