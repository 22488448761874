<template>
  <div id="box">
    <div class="box_one">
      <!-- 导航栏 -->
      <div class="nav">
        <!-- 调度对讲 -->
        <div class="nav_box flex">
          <div :class="['nav_name', index == navIndex ? 'nav_name_active' : '']" v-for="(item, index) in navArray"
            :key="item.name" @click="navTab(index)">
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- 导航栏结束 -->
      <!-- 筛选 -->
      <div class="screen">
        <div class="screen_box flex">
          <div>{{ $t('message.filter') }} :</div>
          <el-select v-model.trim="screen" :placeholder="$t('message.all')" @change="changeScreen">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <el-dropdown trigger="click" class="screen_dropdown" v-if="$store.state.userInfo.self_built_group == 1"
          placement="bottom-start" @command="handleCommand">
          <span class="el-dropdown-link mouse">
            <img style="width: 23px;height: 23px;" src="../assets/group/selfbuiltgroup.png" alt="" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in selfGroupArr" :key="item.id" :command="item.id">
              <div class="flex" @mouseover="overSelfGroup(index)" @mouseout="moveSelfGroup(index)">
                <img :src="item.isOver ? item.xzimg : item.img" alt="" />{{ item.title }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <img src="../assets/group/localGroup.png" @click="regionGroup" alt="" /> -->
      </div>
      <!-- 筛选结束 -->
      <!-- 搜索 -->
      <div class="search flex">
        <div class="search_input flex">
          <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
          <!-- reserve-keyword -->
          <!-- <el-select style="width: 100%" v-model="search" filterable remote placeholder="群组/用户"
            :remote-method="remoteMethod" :loading="loading">
            <el-option v-for="item in optionsArray" :key="item.id" :label="item.label" :value="item.label">
            </el-option>
          </el-select> -->
          <!-- @input="remoteMethod" -->
          <el-input v-model="search" @focus="focus" @blur="blur" :placeholder="$t('message.groupUser')"></el-input>
          <img class="mouse" src="../assets/group/gb.png" @click="query(1)" alt="" />
        </div>
        <div class="search_but mouse" @click="query()">
          <img src="../assets/group/search.png" alt="" />
        </div>
      </div>
      <!-- 搜索结束 -->
      <!-- 树级控件 -->
      <div class="treeLevel" ref="treeLevel" :style="{ overflow: $store.state.prohibitRoll ? 'hidden' : 'auto' }">
        <div style="width:100%;">
          <!-- lazy :load="loadNode" -->
          <el-tree v-if="openPanel" :data="groupArray" show-checkbox @check="check" node-key="key" ref="tree"
            :highlight-current="isHighlight" :props="defaultProps" :disabled="false" @node-expand="handleNodeExpand"
            @node-collapse="collapse" :expand-on-click-node="false" :current-node-key="choiceNameData.key"
            :default-checked-keys="checkedArray" :default-expanded-keys="expandedKeys">
            <div style="width:100%;" slot-scope="{ node, data }" ref="element">
              <el-popover popper-class="my_popover" transition="el-collapse-transition" placement="right-start"
                @show="onmouseover(data)" @hide="onmouseout(data)" :open-delay="500" :offset="-4" :close-delay="200"
                trigger="hover" :visible-arrow="false">
                <!-- @mouseenter="onmouseover(data)" @mouseleave="onmouseout(data)" -->
                <div
                  :class="['treeLevel_popup mouse prohibitCopy', $store.state.language == 'en' ? 'treeLevel_wid_English' : '']"
                  v-show="!data.children">
                  <div class="treeLevel_popup_box flex_between">
                    <div>{{ $t('message.voiceIntercom') }}</div>
                    <el-switch style="margin-left: 15px" @change="changeUpData(0, data)" v-model="data.audioEnabled"
                      :inactive-value="false" :active-value="true" active-color="var(--main-color)"
                      inactive-color="#838383">
                    </el-switch>
                  </div>
                  <!-- <div class="treeLevel_popup_box flex_between">
                    <div>{{ $t('message.baseStationSwitch') }}</div>
                    <el-switch style="margin-left: 15px" @change="changeUpData(1, data)"
                      v-model="data.celllocation_switch" :inactive-value="false" :active-value="true"
                      active-color="var(--main-color)" inactive-color="#838383">
                    </el-switch>
                  </div> -->
                  <div class="treeLevel_popup_box flex_between" v-if="data.services && data.services.gps">
                    <div>{{ $t('message.gpsSwitch') }}</div>
                    <el-switch style="margin-left: 15px" @change="changeUpData(2, data)" v-model="data.GPSswitch"
                      :inactive-value="false" :active-value="true" active-color="var(--main-color)"
                      inactive-color="#838383">
                    </el-switch>
                  </div>
                  <div class="treeLevel_popup_ms flex" v-if="data.services && data.services.gps && data.GPSswitch">
                    <div class="treeLevel_popup_ms_num" v-for="(item, index) in secondArray" :key="index" :class="item.name == data.locPeriod
                      ? 'treeLevel_popup_ms_num_red'
                      : ''
                      " @click="bindQh(data, index)">
                      {{ item.name }}s
                    </div>
                  </div>
                  <div style="padding-top: 15px" @click="edit(data, 3)">
                    {{ $t('message.viewHistoricalTrack') }}
                  </div>
                </div>
                <!-- 用户状态结束 -->
                <!-- 编辑 -->
                <div class="treeLevel_popup mouse prohibitCopy" style="padding:0px;"
                  v-show="data.children && data.GroupType == 1">
                  <div class="treeLevel_popup_bg" @click="edit(data, 1)">
                    {{ $t('message.edit') }}
                  </div>
                  <template v-if="data.mmcount > 0">
                    <div class="treeLevel_popup_bg" @click="selfGroup(data, 1)">
                      {{ $t('message.Grouppassword') }}
                    </div>
                    <div class="treeLevel_popup_bg" @click="selfGroup(data, 2)">
                      {{ $store.state.userInfo.User_ID == data.mmcount ? $t('message.Dissolve') : $t('message.Exit') }}
                    </div>
                  </template>
                </div>
                <!-- 区域群组编辑 -->
                <div class="treeLevel_popup mouse prohibitCopy" style="padding:0px;"
                  v-show="data.children && data.GroupType == 4">
                  <div class="treeLevel_popup_bg" @click="regionEdit(data, 1)">
                    {{ $t('message.edit') }}
                  </div>
                  <div class="treeLevel_popup_bg" @click="regionEdit(data, 2)">
                    {{ $t('message.editArea') }}
                  </div>
                  <div class="treeLevel_popup_bg" @click="regionEdit(data, 3)">
                    {{ $t('message.delete') }}
                  </div>
                </div>
                <!-- 编辑 -->
                <template slot="reference">
                  <div class="treeLevel_tree">
                    <div class="treeLevel_left flex">
                      <div v-show="!data.children" style="height: 16px;">
                        <img class="treeLevel_right_img_one" style="margin-right: 5px" :src="upUserImg(data)" alt="" />
                      </div>
                      <div v-html="eachColor(node.label, search1)" @click.prevent="choiceName(data)"
                        :style="data.children ? 'width: 120px;' : 'flex: 1;'" class="treeLevel_left_label"
                        :class="upLabelColor(data)">
                        {{ node.label }}
                      </div>
                      <div v-if="data.children">
                        <span style="color: var(--main-color-tow)">{{ data.num }}</span>/{{ data.total }}
                      </div>
                    </div>
                    <!-- 编辑 -->
                    <div class="treeLevel_right flex" v-show="data.children">
                      <span :class="[
                        'treeLevel_right_jr',
                        data.userState ? 'treeLevel_right_color' : ''
                      ]" @click="getInto(data)">{{ $t('message.enter') }}</span>
                    </div>
                    <div class="treeLevel_right flex" v-show="!data.children">
                      <!-- <img class="treeLevel_right_img_one" v-if="data.services && data.isBeyond" src="../assets/group/Crosstheborder.png"
                        alt="" /> -->
                      <img class="treeLevel_right_img_one" v-if="data.services && data.services.soundRecording"
                        src="../assets/group/soundrecording.png" alt="" />
                      <img class="treeLevel_right_img_one" v-if="data.services && data.services.sos"
                        src="../assets/group/sos.png" alt="" />
                      <img class="treeLevel_right_img_one" v-if="data.services && data.services.enclosure"
                        src="../assets/group/enclosure.png" alt="" />
                      <img v-if="data.services && data.services.gps && data.GPSswitch" class="treeLevel_right_img_one"
                        src="../assets/group/gps.png" alt="" />
                      <img v-if="data.services && data.services.gps && !data.GPSswitch" class="treeLevel_right_img_one"
                        src="../assets/group/gps1.png" alt="" />
                      <img v-if="data.services &&
                        !data.services.gps &&
                        data.celllocation_switch
                        " class="treeLevel_right_img_one" src="../assets/group/jwdd.png" alt="" />
                      <img v-if="data.services &&
                        !data.services.gps &&
                        !data.celllocation_switch
                        " class="treeLevel_right_img_one" src="../assets/group/jwdd1.png" alt="" />
                    </div>
                  </div>
                </template>
              </el-popover>
            </div>
          </el-tree>
        </div>
      </div>
      <!-- 树级控件 -->
      <div ref="bottomDj" style="border-top: 1px solid var(--main-color); padding: 10px 0px">
        <!-- 调度对讲 -->
        <div class="bottom">
          <div class="bottom_box" v-for="(item, index) in ddGroupArray" :key="item.id" @click="groupMethod(item, index)"
            v-preventReClick>
            <img :src="item.url" alt="" />
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 通话弹出框 -->
    <dhPopup ref="dhPopup" v-if="false"></dhPopup>
    <!-- 编辑 -->
    <dialogbox ref="dialogbox" :dialogData="userData"></dialogbox>
    <editGroup v-if="isOpen" :regionId="regionId" :Gid="Gid"></editGroup>
    <addselfgroup v-if="isSelfGroup" @selfgroupSuccess="selfgroupSuccess" @selfgroupClose="selfgroupClose"></addselfgroup>
    <joingroup v-if="isJoinGroup" @joingroupSuccess="joingroupSuccess" :seflGroupData="seflGroupData" :joinType="joinType"
      @joingroupClose="joingroupClose"></joingroup>
    <tipsPopup v-if="isTipsPopup" :tipsData="tipsData" @closeTips="closeTips" @successTips="successTips"></tipsPopup>
    <usertrajectory v-if="timeShow" :dialogData="userData" @closeUsertrajectory="closeUsertrajectory"
      @successUsertrajectory="successUsertrajectory"></usertrajectory>
    <editqz v-if="bgShow" :dialogData="userData" @closeEditqz="closeEditqz" @successEditqz="successEditqz"></editqz>
  </div>
</template>  

<script>
import { _debounce } from "@/method/utils.js";
import dhPopup from "@/components/dispatchLntercom/dhPopup.vue";
import dialogbox from "@/components/dispatchLntercom/dialogbox.vue";
import EventBus from "@/eventBus/eventBus.js";
import addselfgroup from "@/components/dispatchLntercom/addselfgroup.vue";
import joingroup from "@/components/dispatchLntercom/joingroup.vue";
import editGroup from "@/components/dispatchLntercom/editGroup.vue";
import tipsPopup from "@/components/dispatchLntercom/tipsPopup.vue";
import usertrajectory from "@/components/dispatchLntercom/usertrajectory.vue";
import editqz from "@/components/dispatchLntercom/editqz.vue";
import {
  queryUserService,
  updateUserStatus,
  getRegionId
} from "../administration/dispatchLntercom.js";
import axios from "axios";
import qs from "qs";
import beasconfig from "@/api/beasconfig.js"
const regionUrlapi = beasconfig.regionUrlapi;
export default {
  components: {
    dhPopup,
    dialogbox,
    editGroup,
    addselfgroup,
    joingroup,
    tipsPopup,
    usertrajectory,
    editqz
  },
  props: {

  },
  data() {
    return {
      navArray: [
        {
          id: 1,
          name: this.$t('message.allGroups')
        },
        {
          id: 2,
          name: this.$t('message.fixedGroup')
        },
        // {
        //   id: 3,
        //   name: this.$t('message.regionalGroup')
        // }
      ],
      navIndex: 0, //群组tab下标
      options: [
        {
          //筛选类型
          value: "-1",
          label: this.$t('message.all')
        },
        {
          value: "3",
          label: this.$t('message.online')
        },
        {
          value: "1",
          label: this.$t('message.offline')
        }
      ],
      defaultProps: {
        children: "children",
        label: "label",
        isLeaf: 'isBool'
      },
      screen: "",
      ddGroupArray: [
        //调度对讲群组底部方法
        // {
        //   id: 1,
        //   name: this.$t('message.communicatebytelephone'),
        //   url: require("@/assets/group/group1.png"),
        // },
        {
          id: 2,
          name: this.$t('message.call'),
          url: require("@/assets/group/group2.png")
        },
        {
          id: 3,
          name: this.$t('message.hale'),
          url: require("@/assets/group/group3.png")
        },
        {
          id: 4,
          name: this.$t('message.Forceddemolition'),
          url: require("@/assets/group/group4.png")
        }
      ],
      search: "",
      search1: "",
      loading: false,
      optionsArray: [],
      list: [],
      secondArray: [{ name: "10" }, { name: "30" }, { name: "60" }], //秒數
      secondIndex: null,
      userInfo: {},
      userData: {}, //用户数据
      choiceData: [], //选中的数据
      checkedArray: [],
      bgShow: false,
      timeShow: false,
      groupArray: [],
      expandedKeys: [],
      isQuery: false,
      choiceNameData: "",
      timeDate: {
        isShow: false
      },
      top: 0,
      isHighlight: false,
      isOpen: false,    //是否显示区域群组编辑
      regionId: 0,
      openPanel: true,
      Gid: 0,   //群组id
      isSelfGroup: false, //新建群组
      isJoinGroup: false,  //加入群组
      isTipsPopup: false,  //自建组弹出框
      joinType: 0,  //加入群组类型
      seflGroupData: {},
      tipsData: {
        title: this.$t('message.Disbandselforganizedgroup'),
        content: this.$t('message.ExiDissolvetheselfestablishedgroupt'),
        id: 0,
        mmcount: 0  //自建组id
      }, //提示
      selfGroupArr: [
        {
          id: 1,
          title: this.$t('message.newgroup'),
          img: require('../assets/group/newGroup.png'),
          xzimg: require('../assets/group/newGroup1.png'),
          isOver: false
        },
        {
          id: 2,
          title: this.$t('message.Joingroup'),
          img: require('../assets/group/joinGroup.png'),
          xzimg: require('../assets/group/joinGroup1.png'),
          isOver: false
        }
      ]
    };
  },
  created() {
    let that = this;
    that.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    // that.groupArray = that.$store.state.groupUserData;
    EventBus.$on("successMsg", function () {
      //语音对讲
      that.choiceData = []; //选中的数据
      that.checkedArray = [];
    });
    EventBus.$on("nowSpeech", function (uid, gid, bool) {
      //正在讲话
      that.nowSpeech(uid, gid, bool);
    });
    EventBus.$on("selectKey", function () {
      //强制选中
      that.selectKey();
    });
    EventBus.$on("screenData", function (data) {
      that.screenData(data);
    });
    EventBus.$on("updateData", function (sosInfo, typeName) {    //更新单独一条服务数据
      let groupArray = that.groupArray;
      sosInfo = JSON.parse(sosInfo);
      if (typeName == "monitorMsg") {
        groupArray.forEach((item, index) => {
          let bool = sosInfo.some(row => row == item.Gid);
          if (bool) {
            that.$set(that.groupArray[index], 'isMonitor', true);
          } else {
            that.$set(that.groupArray[index], 'isMonitor', false);
          }
        })
        that.refreshTree();
        that.$store.state.groupUserData = groupArray;
        return
      }
      function into(f) {
        if (f < groupArray.length) {
          let i =
            groupArray.length > 0
              ? groupArray[f].children.findIndex(
                item => item.id == sosInfo.user_id
              )
              : -1;
          if (i >= 0) {
            let changeChild = {
              ...groupArray[f].children[i],
              services: sosInfo.services,
              GPSswitch: sosInfo.user_gpsswitch == 1 ? true : false,
              locPeriod: sosInfo.user_gpsfrequency,
              celllocation_switch: sosInfo.celllocation_switch,
              audioEnabled: sosInfo.user_audiostatus == 0 ? false : true
            };
            that.$set(that.groupArray[f].children, i, changeChild);
          }
          into(f + 1);
        } else {
          that.refreshTree();
          that.$store.state.groupUserData = groupArray;
        }
      }
      into(0);
    });
  },
  beforeDestroy: function () {
    EventBus.$off("successMsg");
    EventBus.$off("nowSpeech");
    EventBus.$off("selectKey");
    EventBus.$off("screenData");
    EventBus.$off("updateData");
  },
  mounted() { },
  methods: {
    eachColor(content, searchVal) {
      if (content == undefined) {

        return
      }
      let nt = '<span style="color:var(--main-color);">' + searchVal + "</span>";
      return content.replace(searchVal, nt) + "&nbsp;";
    },
    upLabelColor(row) {
      let text = '';
      if (this.choiceNameData.id == row.id || row.queryState) {
        text = 'blue';
      } else if (row.mmcount > 0 && row.GroupType != 2) {
        text = 'yelow2';
      } else if (row.GroupType == 2) {
        text = 'yelow';
      } else if (row.GroupType == 4) {
        text = 'blue_qs';
      }
      return text;
    },
    upUserImg(data) {  //用户对讲图标
      let bool = data.id == data.mmcount;
      if (data.isStartSpeech) {  //正在讲话
        if (data.role == 0 && data.online == 3) {
          return bool ? require("@/assets/group/zzjhdyh1.png") : require("@/assets/group/zzjhdyh.png");
        }

        if (data.role == 3 && data.online == 3) {
          return bool ? require("@/assets/group/zzjh1.png") : require("@/assets/group/zzjh.png");
        }
      } else {
        // 摇闭开始
        if (data.role == 0 && data.online != 3 && !data.audioEnabled) {
          return bool ? require("@/assets/group/shakeCloseLx2.png") : require("@/assets/group/shakeCloseLx.png");
        }
        // 离线
        if (data.role == 3 && data.online != 3 && !data.audioEnabled) {
          return bool ? require("@/assets/group/shakeCloseLx3.png") : require("@/assets/group/shakeCloseLx1.png");
        }
        // 在线
        if (data.role == 0 && data.online == 3 && data.Ingroup && !data.audioEnabled) {
          return bool ? require("@/assets/group/shakeCloseZx2.png") : require("@/assets/group/shakeCloseZx.png");
        }

        if (data.role == 3 && data.online == 3 && data.Ingroup && !data.audioEnabled) {
          return bool ? require("@/assets/group/shakeCloseZx3.png") : require("@/assets/group/shakeCloseZx1.png");
        }
        // 在线不再组
        if (data.role == 0 && data.online == 3 && !data.Ingroup && !data.audioEnabled) {
          return bool ? require("@/assets/group/shakeCloseDdy2.png") : require("@/assets/group/shakeCloseDdy.png");
        }

        if (data.role == 3 && data.online == 3 && !data.Ingroup && !data.audioEnabled) {

          return bool ? require("@/assets/group/shakeCloseDdy3.png") : require("@/assets/group/shakeCloseDdy1.png");
        }
        // 摇闭结束

        if (data.role == 0 && data.online != 3 && data.audioEnabled) {

          return bool ? require("@/assets/group/off-lineUser1.png") : require("@/assets/group/off-lineUser.png");
        }

        if (data.role == 3 && data.online != 3 && data.audioEnabled) {

          return bool ? require("@/assets/group/off-lineDdy1.png") : require("@/assets/group/off-lineDdy.png");
        }

        if (data.role == 0 && data.online == 3 && data.Ingroup && data.audioEnabled) {

          return bool ? require("@/assets/group/xzUser1.png") : require("@/assets/group/xzUser.png");
        }

        if (data.role == 0 && data.online == 3 && !data.Ingroup && data.audioEnabled) {

          return bool ? require("@/assets/group/bzzUser1.png") : require("@/assets/group/bzzUser.png");
        }

        if (data.role == 3 && data.online == 3 && data.Ingroup && data.audioEnabled) {

          return bool ? require("@/assets/group/zzDdy3.png") : require("@/assets/group/zzDdy1.png");
        }

        if (data.role == 3 && data.online == 3 && !data.Ingroup && data.audioEnabled) {

          return bool ? require("@/assets/group/zzDdy2.png") : require("@/assets/group/zzDdy.png");
        }
      }
    },
    //新建群组
    overSelfGroup(index) {
      this.selfGroupArr[index].isOver = true;
      this.$set(this.selfGroupArr[index], 'isOver', true)
      console.log(this.selfGroupArr[index].isOver)
    },
    moveSelfGroup(index) {
      this.selfGroupArr[index].isOver = false;
    },
    selfgroupSuccess() {
      this.isSelfGroup = false;
    },
    selfgroupClose() {
      this.isSelfGroup = false;
    },
    // 加入群组
    joingroupSuccess() {
      this.joinType = 0;
      this.isJoinGroup = false;
    },
    joingroupClose() {
      this.isJoinGroup = false;
      this.seflGroupData = {};
      this.joinType = 0;
    },
    successTips() {
      this.isTipsPopup = false;
      if (this.$store.state.userInfo.User_ID == this.tipsData.mmcount) {  //判断是否是自己创建的群组
        axios.post(`${beasconfig.selfUrlapi}/group/deleteGroup`, qs.stringify({
          creator: this.$store.state.userInfo.User_ID,
          cgId: this.tipsData.id
        }),
          {
            headers: beasconfig.tokenSl
          })
          .then(() => {

          })
      } else {
        axios.post(`${beasconfig.selfUrlapi}/group/autoExit`, qs.stringify({
          uId: this.$store.state.userInfo.User_ID,
          cgId: this.tipsData.id
        }),
          {
            headers: beasconfig.tokenSl
          })
          .then(() => {

          })
      }
    },
    closeTips() {
      this.isTipsPopup = false;
    },
    handleCommand(e) {
      if (e == 1) {
        this.isSelfGroup = true;
      } else {
        this.isJoinGroup = true;
      }
    },
    selfGroup(row, type) {
      if (type == 1) {
        this.seflGroupData = row;
        this.joinType = 1;
        this.isJoinGroup = true;
      } else {
        let bool = this.$store.state.userInfo.User_ID == row.mmcount;
        this.tipsData.title = bool ? this.$t('message.Disbandselforganizedgroup') : this.$t('message.Exittheselfcreatedgroup');
        this.tipsData.content = bool ? this.$t('message.ExiDissolvetheselfestablishedgroupt') : this.$t('message.Exitselfbuiltgroup');
        this.tipsData.id = row.id;
        this.tipsData.mmcount = row.mmcount;
        this.isTipsPopup = true;
      }
    },
    changeScreen(e) {
      //筛选数据
      let groupUserData = JSON.parse(
        JSON.stringify(this.$store.state.groupUserData)
      );
      groupUserData.forEach(item => {
        if (e == 3) {
          item.num = item.children.filter(item1 => item1.online == 3).length;
          item.children = item.children.filter(item1 => item1.online == 3);
        } else if (e == 1) {
          item.num = 0;
          item.children = item.children.filter(item1 => item1.online == 1);
        } else {
          item.num = item.children.filter(item1 => item1.online == 3).length;
        }
      });
      if (this.navIndex == 1) {
        groupUserData = groupUserData.filter(item => item.GroupType == 1);
      } else if (this.navIndex == 2) {
        groupUserData = groupUserData.filter(item => item.GroupType == 4);
      }
      this.groupArray = groupUserData;
      this.$store.state.onlineStatus = e;
      this.search = "";
      this.search1 = "";
      this.screen = e;
      this.expandedKeys = [];
      this.isQuery = true;
      this.checkedArray = [];
      this.choiceData = [];
      this.$refs.tree.setCheckedKeys([]);
      this.deleteDeploymentCache();
    },
    successUsertrajectory() {
      this.timeShow = false;
    },
    closeUsertrajectory() {
      this.timeShow = false;
    },
    successEditqz() {
      this.bgShow = false;
    },
    closeEditqz() {
      this.bgShow = false;
    },
    edit(row, type) {
      //编辑
      this.userData = row;
      if (type == 1) {
        this.bgShow = true;
      } else {
        this.timeShow = true;
      }
    },
    regionEdit(row, type) {
      getRegionId({ groupId: row.Gid }).then(res => {
        if (res.data.code == 200) {
          if (!res.data.data) {
            this.$MessageWarning(this.$t('message.message.deletedGro'));
            EventBus.$emit("strongBrushList");
            return
          }
          row.regionId = res.data.data.region_id;
          this.regionId = res.data.data.region_id;
          if (type == 1) {
            this.Gid = row.Gid;
            this.isOpen = false;
            setTimeout(() => {
              this.isOpen = true;
            }, 300)
          }
          if (type == 2) {
            this.choiceNameData = "";
            EventBus.$emit('editEnclosureMap', row, 1)
          }
          if (type == 3) {
            this
              .$confirm(this.$t('message.deleteLocalgroup'), this.$t('message.message.prompt'), {
                confirmButtonText: this.$t('message.confirm'),
                cancelButtonText: this.$t('message.cancel'),
                type: "warning"
              })
              .then(() => {
                this.deleteRegionGroup(row);
              })
              .catch(() => { });
          }
        } else {
          this.$MessageWarning(res.data.msg);
        }
      })
    },
    refreshTree() {    //刷新树     懒加载会导致重复数据所以需要重新创建节点
      this.openPanel = false;
      this.$nextTick(() => {
        this.openPanel = true;
      })
    },
    deleteRegionGroup(data) {  //删除区域群组
      axios.delete(`${regionUrlapi}-${this.$store.state.userState.server}/region/${data.regionId}`)
        .then(res => {
          if (res.data.code == 200) {
            let array = [];
            let regionGroup = JSON.parse(sessionStorage.getItem("regionGroup"));
            if (regionGroup) {
              array = regionGroup
            }
            array.push(data.Gid);
            sessionStorage.setItem("regionGroup", JSON.stringify(array));
            let i = this.$store.state.groupUserData.findIndex(item => item.Gid == data.Gid);
            let index = this.groupArray.findIndex(item => item.Gid == data.Gid);
            this.groupArray.splice(index, 1);
            this.$store.state.groupUserData.splice(i, 1);
            this.$store.state.recordAddRegionNum -= 1;
            if (this.choiceNameData != "") {
              this.choiceNameData = "";
            }
            this.refreshTree();
            EventBus.$emit("emptyMap", 2);
          } else {
            this.$MessageWarning(res.data.msg);
          }
        })
    },
    check(row, tree) {
      //选中树级菜单的值
      this.choiceData = tree.checkedNodes;
      this.checkedArray = tree.checkedNodes
        .filter(item => item.online == 3)
        .map(item => item.key);
      // let list = [];
      // tree.checkedNodes.forEach(item => {
      //   if (item.children) {
      //     list.push(...item.children);
      //   } else {
      //     list.push(item);
      //   }
      // })
      // this.choiceData = this.deweight(list).filter(item => item.online == 3);
      // this.checkedArray = list.filter(item => item.online == 3).map(item => item.key);
      sessionStorage.setItem("checkedArray", JSON.stringify(this.checkedArray)); //缓存选中的值
    },
    bindMonitorGroup(name, type, data) {
      //监听群组
      let f = this.groupArray.findIndex(item => item.Gid == data.Gid);
      let n = this.$store.state.groupUserData.findIndex(item => item.Gid == data.Gid);
      if (name == "monitor") {
        this.groupArray[f].isMonitor = !this.groupArray[f].isMonitor;
        EventBus.$emit("bindMonitorGroup", name, type, data);
      } else {
        this.groupArray[f].isRadioBroadcast = type == 1 ? true : false;
        this.$store.state.groupUserData[n].isRadioBroadcast = type == 1 ? true : false;
        let arr = this.groupArray
          .filter(item => item.isRadioBroadcast == true)
          .map(item => item.key);
        sessionStorage.setItem("radioBroadcastState", JSON.stringify(arr));
      }
      sessionStorage.setItem("groupArray", JSON.stringify(this.groupArray));
    },
    // 切换标签卡
    navTab(index) {
      if (this.navIndex == index) {
        return;
      }
      this.navIndex = index;
      this.groupArray = [];
      this.screen = "";
      this.$store.state.onlineStatus = "";
      this.search = "";
      this.search1 = "";
      this.checkedArray = [];
      this.expandedKeys = [];
      this.choiceData = [];
      this.$refs.tree.setCheckedKeys([]);
      this.deleteDeploymentCache();
    },
    groupMethod(item) {
      //左侧切换内容
      //组方法
      let choiceData = this.choiceData;
      let online = this.deweight(choiceData);
      if (this.choiceData.length == 0) {
        let arr = ['', 'message.callingusers', 'message.Forcedpullusers', 'message.Forceddismantlingusers'];
        this.$MessageWarning(this.$t(arr[item.id - 1]));
        return;
      }
      if (this.choiceData.length == 0) {
        this.$MessageWarning(`${this.$t('message.Forcedpullusers')}`);
        return;
      }
      if (item.id == 1) {
        //通话
        // this.$refs.dhPopup.openPhone();
        if (this.$store.state.voiceCallInfo.callType != 0) {
          this.$MessageWarning(this.$t('message.message.Voicecallinprogress'));
          return
        }
        if (this.$store.state.videoCallInfo.callType != 0) {
          this.$MessageWarning(this.$t('message.message.Videocallinprogress'));
          return
        }
        if (this.$store.state.userState.fullDuplex == 0) {
          this.$MessageWarning(this.$t('message.message.Voicecallfunction'));
          return
        }
        let groupUserData = JSON.parse(JSON.stringify(this.$store.state.groupUserData));
        let temporaryGroup = groupUserData.filter(item => item.GroupType == 2);   //判断当前群组列表是否有临时群组
        let temporaryGroupTow = temporaryGroup.some(item => this.$store.state.soketUserInfo.gid == item.Gid);
        if (temporaryGroup && temporaryGroup.length > 0 && temporaryGroupTow) {
          this.$MessageWarning(this.$t('message.message.Alreadytemporary'));
          return
        }
        if (online.length > 1) {
          this.$MessageWarning(this.$t('message.message.Onlysupports'));
          return
        }
        queryUserService({
          uId: this.userInfo.id,
          uids: online.map(item => item.id).join(',')
        }).then(res => {
          let bool = res.data.data.some(item => item.full_duplex == 0);
          if (bool) {
            this.$MessageWarning(this.$t('message.message.Enablevoicecalls'));
          } else {
            this.$store.state.voiceCallInfo.callName = online[0].label;
            EventBus.$emit("startCall", online.map(item => item.id));
          }
        });
        // return
      }
      if (item.id == 2 && this.$store.state.voiceCallInfo.callType != 0) {
        this.$MessageWarning(this.$t('message.message.Oncall'));
        return
      }
      if (item.id == 2 && this.$store.state.audio_enable != 1) {
        this.$MessageWarning(this.$t('message.message.Shaketodeath'));
        return
      }
      if (item.id != 1) {
        let array = online.filter(item => item.online == 3);
        let obj = {
          ids: array.map(item => item.id),
          row: array
        };
        EventBus.$emit("menuOption", item.id, obj);
      }
      this.choiceData = []; //选中的数据
      this.checkedArray = [];
      this.$refs.tree.setCheckedKeys([]);
      sessionStorage.removeItem("checkedArray");
      this.refreshTree();
    },
    deweight(arr) {
      //数组去重
      let de_arr = [];
      arr.forEach(i => {
        let isTrue = de_arr.every(j => j.id != i.id);
        isTrue ? de_arr.push(i) : "";
      });
      return de_arr;
    },
    deweightOne(arr) {
      let de_arr = [];
      arr.forEach(i => {
        let isTrue = de_arr.every(j => j != i);
        isTrue ? de_arr.push(i) : "";
      });
      return de_arr;
    },
    deweightTow(arr) {
      let de_arr = [];
      arr.forEach(i => {
        let isTrue = de_arr.every(j => j.Gid != i.Gid);
        isTrue ? de_arr.push(i) : "";
      });
      return de_arr;
    },
    query: _debounce(function (type) {
      //查询方法
      let groupArray = JSON.parse(
        JSON.stringify(this.groupArray)
      );
      // this.groupArray
      if (type == 1) {
        this.search = "";
        this.search1 = "";
        // return
      }
      this.isQuery = true;
      this.checkedArray = []; //清空选中的值
      this.expandedKeys = [];
      this.choiceData = [];
      this.$refs.tree.setCheckedKeys([]);
      this.deleteDeploymentCache();
      if (this.search !== "") {
        groupArray.forEach(item => {
          if (
            item.label.indexOf(this.search) > -1
          ) {
            //先检索是否是群组
            if (item.children.length > 0) {
              this.expandedKeys.push(item.key);
            }
            this.handleNodeExpand(item, item, 1);
          }
          item.children.filter((itemOne, index) => {
            if (
              itemOne.label.indexOf(this.search) >
              -1
            ) {
              let bool = this.expandedKeys.some(itemTow => itemTow == item.key);
              if (!bool) {  //判断相同的则不进入
                this.expandedKeys.push(item.key);
                this.handleNodeExpand(item, item, 1);
              }
              if (itemOne.label == this.search) {
                item.children[index].queryState = true;
              }
            }
          });
          if (this.screen == 3) {
            item.num = item.children.filter(item1 => item1.online == 3).length;
            item.children = item.children.filter(item1 => item1.online == 3);
          } else if (this.screen == 1) {
            item.num = 0;
            item.children = item.children.filter(item1 => item1.online == 1);
          }
        });
        this.search1 = this.search;
        this.expandedKeys = this.deweightOne(this.expandedKeys);
        sessionStorage.setItem("openArray", JSON.stringify(this.expandedKeys));
      } else {
        this.search1 = "";
        groupArray.forEach(item => {
          if (this.screen == 3) {
            item.num = item.children.filter(item1 => item1.online == 3).length;
            item.children = item.children.filter(item1 => item1.online == 3);
          } else if (this.screen == 1) {
            item.num = 0;
            item.children = item.children.filter(item1 => item1.online == 1);
          }
        });
      }
      if (this.navIndex == 0) {
        this.groupArray = groupArray;
      } else if (this.navIndex == 1) {
        this.groupArray = groupArray.filter(item => item.GroupType == 1);
      } else {
        this.groupArray = groupArray.filter(item => item.GroupType == 4);
      }
      this.$forceUpdate();
    }, 200),
    focus() {
      //获取焦点
      this.$store.state.isFocusing = true;
    },
    blur() {
      //失去焦点
      this.$store.state.isFocusing = false;
    },
    arrayMerge() {
      //数据合并
      let groupUserData = this.$store.state.groupUserData;
      let array = [];
      groupUserData.forEach(item => {
        item.children.filter(itmeOne => {
          array.push(item, itmeOne);
        });
      });
      return this.deweight(array);
    },
    async loadNode(node, resolve) {
      if (node.level === 0) {
        resolve(node.data);
        return
      }
      if (node.data.children.length <= 0) {
        resolve([]);
        return
      }
      let bool = node.data.children.every(item => item.services);
      if (bool) {     //判断服务有没有，有则不掉接口
        // setTimeout(() => {
        const data = node.data.children;
        resolve(data);
        // }, 500);
        return
      }
      let i = this.groupArray.findIndex(item => item.Gid == node.data.Gid);
      let groupUserData = this.$store.state.groupUserData;
      let f = groupUserData.findIndex(r => r.Gid == node.data.Gid);
      let array = node.data.children.map(item => item.id).join(); //集合id
      await queryUserService({
        uId: this.userInfo.id,
        uids: array
      }).then(res => {
        res.data.data.forEach(item => {
          node.data.children.forEach((item1, index) => {
            if (item.User_ID == item1.id && !item1.services) {
              this.$set(this.groupArray[i].children, index, {
                ...item1,
                ...item
              });
            }
          });
          groupUserData[f].children.forEach((r, m) => {     //附加数据防止报错
            if (item.User_ID == r.id && !r.services) {
              this.$set(this.$store.state.groupUserData[f].children, m, {
                ...r,
                ...item
              });
            }
          })
        });
      });
      const data = node.data.children;
      resolve(data);
    },
    //
    async handleNodeExpand(row, tree, type) {
      if (type != 1) {
        this.expandedKeys.push(tree.key);
      }
      //展开调用数据
      let i = this.groupArray.findIndex(item => item.id == row.id);
      let array = row.children.map(item => item.id).join(); //集合id
      let bool = row.children.every(item => item.services);
      if (!bool) {     //判断服务有没有，有则不掉接口
        // 获取成员服务状态
        await queryUserService({
          uId: this.userInfo.id,
          uids: array
        }).then(res => {
          res.data.data.forEach(item => {
            row.children.forEach((item1, index) => {
              if (item.User_ID == item1.id) {
                // setTimeout(() => {
                this.$set(this.groupArray[i].children, index, {
                  ...item1,
                  ...item
                });
                // }, 100)
              }
            });
          });
        });
      }
      this.isHighlight = false;
      sessionStorage.setItem("openArray", JSON.stringify(this.expandedKeys));
      sessionStorage.setItem("groupArray", JSON.stringify(this.groupArray));
      sessionStorage.setItem("checkedArray", JSON.stringify(this.checkedArray)); //缓存选中的值
      await this.$refs.tree.setCheckedKeys(this.checkedArray);
    },
    collapse(row, tree) {
      //收起
      // console.log(row, tree);
      this.isHighlight = false;
      let index = this.expandedKeys.findIndex(item => item == tree.data.key);
      this.expandedKeys.splice(index, 1);
      sessionStorage.setItem("openArray", JSON.stringify(this.expandedKeys));
    },
    changeUpData(type, row) {
      //修改用户状态
      if (type == 1) {
        let data = {
          uid: row.id,
          audioStatus: row.audioEnabled ? 1 : 0,
          gpsSwitch: row.GPSswitch ? 1 : 0,
          gpsFrequency: row.locPeriod,
          celllocationSwitch: row.celllocation_switch ? 1 : 0
        };
        updateUserStatus(data).then(res => {
          if (res.data.code == 200) {
            this.changeDataStatus(type, row);
          } else {
            this.$MessageWarning(res.data.msg);
          }
        });
      } else {
        EventBus.$emit("upData", type, row);
        this.changeDataStatus(type, row);
      }
    },
    changeDataStatus(type, row) {
      //更改数据状态
      let that = this;
      let children = that.groupArray;
      function into(f) {
        if (f < children.length) {
          let i = children[f].children.findIndex(item => item.id == row.id);
          if (i >= 0) {
            if (type == 0) {
              that.$set(
                children[f].children[i],
                "audioEnabled",
                row.audioEnabled
              ); //语音
            }
            if (type == 1) {
              that.$set(
                children[f].children[i],
                "celllocation_switch",
                row.celllocation_switch
              ); //基站
            }
            if (type == 2) {
              that.$set(children[f].children[i], "GPSswitch", row.GPSswitch); //gps
              that.$set(children[f].children[i], "locPeriod", row.locPeriod);
            }
          }
          into(f + 1);
        } else {
          sessionStorage.setItem("groupArray", JSON.stringify(that.groupArray));
        }
      }
      into(0);
    },
    choiceName(data) {
      //选中数据
      if (this.choiceNameData.id == data.id) {
        this.choiceNameData = "";
        EventBus.$emit("changeMapOne", "", 0);
        // this.$refs.tree.setCurrentKey(null)
      } else {
        this.choiceNameData = data;
        let type = data.children ? 2 : 1;
        let find = type == 1 ? this.groupArray.find(item => item.Gid == data.gid) : null;
        if (data.GroupType == 4 || find && find.GroupType == 4) {
          if (find) {
            this.choiceNameData.Gid = data.gid;
          }
          this.querGetRegionId(type);
        } else {
          EventBus.$emit("changeMapOne", this.choiceNameData, type);
        }
        // this.$refs.tree.setCurrentKey(data.key)
      }
      this.$store.state.mapMarkerIndex = 1;
      this.isHighlight = false;
    },
    querGetRegionId(type) {
      getRegionId({ groupId: this.choiceNameData.Gid }).then(res => {
        if (res.data.code == 200) {
          if (!res.data.data) {
            this.$MessageWarning(this.$t('message.message.deletedGro'));
            this.choiceNameData = "";
            EventBus.$emit("strongBrushList");
            return
          }
          this.choiceNameData.regionId = res.data.data.region_id;
          EventBus.$emit("changeMapOne", this.choiceNameData, type);
        } else {
          this.$MessageWarning(res.data.msg);
        }
      })
    },
    onmouseover(data) {
      this.$refs.tree.setCurrentKey(data.key);
    },
    onmouseout() {
      //鼠标离开触发
      this.$refs.tree.setCurrentKey(null);
    },
    bindQh(data, index) {
      // return
      //选择频率
      let children = this.groupArray;
      var f = children.findIndex(item => item.id == data.gid);
      var i = children[f].children.findIndex(item => item.id == data.id);
      this.groupArray[f].children[i].locPeriod = this.secondArray[index].name;
      EventBus.$emit("upData", 2, data);
    },
    getInto(data) {
      //进入群组
      if (this.$store.state.audio_enable != 1) {
        this.$MessageWarning(this.$t('message.message.Shaketodeath2'));
        return
      }
      let index = this.groupArray.findIndex(item => item.Gid == data.Gid);
      let i = this.groupArray.findIndex(item => item.userState == true);
      if (i == -1) {
        this.groupArray[index].userState = true;
      } else {
        this.groupArray[i].userState = false;
        this.groupArray[index].userState = true;
      }
      EventBus.$emit("getInto", data);
      EventBus.$emit("closeConversation");
    },
    deleteDeploymentCache() {
      //删除缓存
      sessionStorage.removeItem("openArray");
      sessionStorage.removeItem("checkedArray");
    },
    nowSpeech(uid, gid, bool) {
      //正在讲话
      let groupArray = this.groupArray;
      let f = groupArray.findIndex(item => item.Gid == gid);
      if (f == -1) {
        return;
      }
      let i = groupArray[f].children.findIndex(item => item.id == uid);
      if (f >= 0 && i >= 0) {
        this.groupArray[f].children[i].isStartSpeech = bool;
      }
    },
    selectKey() {
      //强制选中
      let checkedArray = JSON.parse(sessionStorage.getItem("checkedArray"));
      if (checkedArray) {
        this.checkedArray = checkedArray;
        setTimeout(() => {
          this.$refs.tree.setCheckedKeys(this.checkedArray);
        }, 50);
      }
    },
    regionGroup() {    //  区域群组
      // let num = this.groupArray.filter(item => item.GroupType == 4).length;
      // num >= 3 || 
      if (this.$store.state.recordAddRegionNum >= 3) {
        this.$MessageWarning(this.$t('message.message.createregiongroups'));
        return
      }
      this.choiceNameData = "";
      this.$customMessageError(this.$t('message.pleaseselectaregion'));
      this.$store.state.efRemark = '1';
      EventBus.$emit("emptyMap", 0);
    },
    screenData(data) {
      //筛选数据
      this.refreshTree();
      let arr = JSON.parse(data);
      let array = [];
      if (arr && arr.length > 0) {
        //是否有传参
        array = this.deweightTow(arr);
      } else {
        array = JSON.parse(JSON.stringify(this.$store.state.groupUserData));
      }
      if (array.length > 0) {
        array.forEach(item => {
          if (this.screen == 3) {
            item.num = item.children.filter(item1 => item1.online == 3).length;
            item.children = item.children.filter(item1 => item1.online == 3);
          } else if (this.screen == 1) {
            item.num = 0;
            item.children = item.children.filter(item1 => item1.online == 1);
          }
        });
        if (this.navIndex == 1) {
          this.groupArray = array.filter(item => item.GroupType == 1);
        } else if (this.navIndex == 2) {
          this.groupArray = array.filter(item => item.GroupType == 4);
        } else {
          this.groupArray = array;
        }
      }
      if (this.choiceData.length > 0) {   //防止临时群组退组还是勾选状态
        this.$store.state.groupUserData.forEach(() => {
          this.choiceData.forEach((item, index) => {
            let id = item.Gid ? item.Gid : item.gid
            let find = this.$store.state.groupUserData.find(r => r.Gid == id);
            if (!find) {
              this.choiceData.splice(index, 1);
            }
          })
        })
      }
      this.refreshTree();
    }
  },
  watch: {
    //监听事件
    "$store.state.groupUserData": {
      handler(newValue) {
        let openArray = JSON.parse(sessionStorage.getItem("openArray"));
        // this.refreshTree();
        if (openArray) {
          this.expandedKeys = openArray;
          this.selectKey();
        } else {
          if (newValue.length > 0) {
            this.screenData(JSON.stringify(this.$store.state.groupUserData));
          }
        }
      },
      // deep: true,
      immediate: true
    },
    navIndex(navIndex) {
      let array = JSON.parse(JSON.stringify(this.$store.state.groupUserData));
      if (navIndex == 1) {
        this.groupArray = array.filter(item => item.GroupType == 1);
      } else if (navIndex == 2) {
        this.groupArray = array.filter(item => item.GroupType == 4);
      } else {
        this.groupArray = array;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
#box {
  width: 300px;
  height: 100%;
  overflow: hidden;
  background: url(../assets/group/leftBg.png);
  background-size: 100% 100%;

  .box_one {
    width: calc(100% - 20px);
    height: calc(100vh - 80px - 20px);
    position: relative;
    top: 10px;
    /* left: 10px; */
    margin: auto;
  }

  /deep/.el-tree {
    background-color: transparent;
    color: #fff;

    .el-tree-node__content {
      height: 30px;
      font-size: 14px;
      font-weight: bold;
    }

    .el-tree-node__content:hover,
    .el-upload-list__item:hover {
      background-color: #343434 !important;
    }

    // .el-tree-node:focus,
    // .is-expanded:focus,
    // .is-focusable:focus {
    //   background-color: #343434 !important;
    // }

    .el-tree-node.is-current>.el-tree-node__content {
      background: #343434;
    }

    .el-tree--highlight-current.el-tree-node.is-current>.el-tree-node__content {
      background: transparent;
      // #343434
    }

    .el-tree .el-tree-node.is-current>.el-tree-node__content {
      background: transparent !important;
    }

    // 禁用的复选框颜色
    .el-checkbox__input.is-disabled .el-checkbox__inner {
      background-color: #4a4b4e !important;
      border-color: #dcdfe6 !important;
    }

    .el-tree-node__expand-icon.expanded {
      color: var(--main-color-tow);
    }

    .el-tree-node__expand-icon {
      color: var(--main-color-tow);
    }

    .el-tree-node__expand-icon.is-leaf {
      color: transparent !important;
      cursor: default;
    }

    .el-checkbox__inner {
      background-color: #343434 !important;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner,
    .el-checkbox__inner:hover {
      border-color: #dcdfe6;
    }

    .el-checkbox__inner::after {
      border-color: var(--main-color);
      margin-left: 0;
    }

    .el-tree-node:focus>.el-tree-node__content {
      background: #343434;
    }

    // .el-tree-node>.el-tree-node__children {
    //   overflow: visible !important;
    // }

    // .el-tree-node.is-expanded.is-focusable .el-tree-node__children {
    //   display: block;
    //   height: auto !important;
    // }

    // .el-tree-node .el-tree-node__children {
    //   height: 0 !important;
    // }

    // .el-tree-node.is-expanded.is-focusable {
    //   overflow: visible;
    // }

    // >.el-tree-node {
    //   overflow-y: clip;
    // }
  }

  .nav {
    width: 100%;
    height: 35px;
    line-height: 35px;
    margin: 0px auto;
    font-size: 14px;
    color: #000000;
    padding-top: 10px;
    border-bottom: 1px solid var(--main-color);

    &_box {
      width: 92%;
      margin: auto;
    }

    &_name {
      flex: 1;
      // width: 88px;
      height: 100%;
      background-color: rgba(39, 118, 195, 0.3);
      border-radius: 3px;
      margin-right: 4px;
      cursor: pointer;
      color: #fff;
    }

    &_name:hover {
      background: var(--main-color);
    }

    &_name_active {
      background: var(--main-color) !important;
    }
  }

  .screen {
    width: 92%;
    margin: auto;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 15px 0px;

    .screen_box {
      flex: 1;

      /deep/.el-input__inner {
        width: 94px;
        height: 28px;
        line-height: 28px;
        margin-left: 10px;
        // padding: 0px 20px;
        border: 2px solid var(--main-color);
        background-color: #4e5656;
        color: #ffffff !important;

        .el-input__inner {
          line-height: 28px;
        }
      }

      /deep/.el-select__input {
        width: 150px !important;
      }
    }

    img {
      width: 26px;
      height: 26px;
    }

  }

  .treeLevel {
    width: calc(100%);
    height: calc(100vh - 80px - 270px);
    padding: 15px 0px;
    overflow: auto;

    &_tree {
      width: 100%;
      display: flex;
      align-items: center;
      //   flex-direction: row;
    }

    &_left {
      flex: 1;
      text-align: left;

      &_label {
        width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &_ls {
        color: #4eb4b4;
      }
    }

    &_right {
      font-size: 12px;
      color: #8a8a8a;
      margin-right: 8px;
      position: relative;

      &_jr {
        width: 50px;
        // padding: 1px 10px;
        border: 1px solid #8a8a8a;
        border-radius: 50px;
      }

      &_color {
        border: none;
        background-color: var(--main-color);
        color: #ffffff;
      }

      &_img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }

      &_img_one {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }

    .treeLevel_popup_one {
      position: fixed !important;
      left: 300px !important;
      // top: 755px !important;
      top: 735px !important;
    }

  }

  /deep/.el-input__icon {
    line-height: 28px !important;
  }

  /deep/.el-select .el-input .el-select__caret {
    color: var(--main-color) !important;
  }

  .search {
    width: 100%;
    height: 30px;
    background-color: var(--main-color);
    border: 2px solid var(--main-color);

    &_input {
      flex: 1;
      height: 100%;
      background-color: #333333;
      color: #ffffff !important;

      /deep/.el-input__inner {
        width: calc(100% - 42px);
        // width: 100%;
        height: 30px;
        line-height: 30px;
        background-color: #333333;
        color: #ffffff !important;
        border: none;
        border-radius: 0px;
        padding: 0px;
      }

      img {
        width: 12px;
        height: 12px;
        margin: 0px 15px;
      }
    }

    &_but {
      width: 40px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: var(--main-color);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 17px;
        height: 17px;
      }
    }
  }

  .bottom {
    width: 92%;
    margin: auto;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: baseline;

    &_box {
      flex: 1;
      // width: 25%;
      text-align: center;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.treeLevel_wid_English {
  width: 180px;
}

.treeLevel_popup {
  // width: 105px;
  background: #4d4d4d;
  background-size: 100% 100%;
  padding: 8px 0px;
  color: #ffffff;
  transition: 0.1s;
  font-size: 12px;
  margin-left: -2px;
  text-align: center;
  position: relative;
  margin-top: -2px;

  &_bg {
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;

    &:hover {
      background-color: #666;
    }
  }

  &_box {
    width: 90%;
    margin: auto;
    margin-bottom: 5px;

    div {
      // flex: 1;
      text-align: center;
    }

    /deep/.el-switch__core {
      width: 28px !important;
      height: 14px !important;
    }

    /deep/.el-switch__core:after {
      width: 10px !important;
      height: 10px !important;
    }

    .is-checked/deep/.el-switch__core:after {
      margin-left: -12px !important;
    }
  }

  &_ms {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    &_num {
      width: 25px;
      height: 13px;
      line-height: 13px;
      text-align: center;
      border: 1px solid #666;
      color: #ffffff;
      margin-right: 5px;

      &_red {
        color: var(--main-color) !important;
        border: 1px solid var(--main-color) !important;
      }
    }
  }
}

.yelow {
  color: #ffb400;
}

.yelow2 {
  color: #FF7E00;
}

.blue {
  color: var(--main-color-tow) !important;
}

.blue_qs {
  color: #4eb4b4;
}

.black {
  color: #343434;
}

.flex {
  display: flex;
  align-items: center;
}

.flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

::-webkit-scrollbar {
  width: 0px;
  height: 100%;
  background-color: #ccc;
  display: none;
}

::-webkit-scrollbar-track {
  width: 0px;
  background-color: #4d4d4d;
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #727171;
  display: none;
}


.el-dropdown-menu {
  // left: 250px !important;
  border: 1px solid #007CC2;
  color: #6AB5FD;
  background-color: #4D4D4D;
  padding: 0px;

  /deep/ .popper__arrow::after {
    border-bottom-color: #4D4D4D !important;
  }

  /deep/ .popper__arrow {
    border-bottom-color: #007CC2 !important;
  }
}

.el-dropdown-menu__item {
  color: #ffffff;
  background-color: #4D4D4D !important;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  align-items: center;
  line-height: 50px;
  padding: 0px !important;
}

.el-dropdown-menu__item>div {
  width: 100%;
  height: 100%;
  padding: 0px 15px;
}

.el-dropdown-menu__item>div>img {
  margin-right: 5px;
}

.el-dropdown-menu__item:first-child {
  // background-color: #4D4D4D !important;
  // color: #ffffff !important;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #007CC2;
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #007CC2;
}
</style>
