<template>
    <div class="box flex" v-if="dialogTableVisible">
        <el-dialog :title="dialogTitle ? dialogTitle : '编辑'" :append-to-body="true" :width="width"
            :before-close="gbDialog" :visible.sync="dialogTableVisible" center>
            <!-- 编辑 -->
            <div class="dialog flex_between" style="height: 505px;" v-if="bgShow">
                <div class="dialog_left">
                    <div class="dialog_left_groupBg" :class="enclosureId == item.id ? 'dialog_left_bgOne' : ''"
                        v-for="item in enclosureArray" :key="item.id" @click="bindSwitchGb(item.id, 2)">
                        {{ item.name }}
                    </div>
                </div>
                <div class="dialog_right">
                    <div class="dialog_group" v-if="enclosureId == 1">
                        <div class="dialog_group_top flex">
                            <div>围栏名称 ：</div>
                            <div class="dialog_group_input">
                                <el-input v-model="fenceName" @focus="focus" @blur="blur" @input="bindInput"
                                    placeholder="请输入围栏名称"></el-input>
                            </div>
                            <div class="dialog_group_red">*由1-15位中文,数字,字母，下划线组成，且不能以下划线开头！</div>
                        </div>
                        <div class="dialog_group_top flex" style="color:#fff;height: 40px;">
                            <el-radio v-model="prohibit" label="1">禁入</el-radio>
                            <el-radio v-model="prohibit" label="0">禁出</el-radio>
                        </div>
                        <!-- 开始 -->
                        <div class="dialog_group_user flex_between">
                            <div class="dialog_group_user_text">
                                <div>
                                    选择用户 :
                                </div>
                                <div class="dialog_group_red" style="margin-top: 10px;">
                                    *编辑即生效
                                </div>
                            </div>
                            <div class="dialog_group_user_xz">
                                <div class="dialog_group_user_xz_box">
                                    <div class="dialog_group_user_xz_box_title">在围栏用户</div>
                                    <div class="dialog_group_user_search">
                                        <!-- 搜索 -->
                                        <div class="search search_one flex">
                                            <div class="search_input flex">
                                                <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
                                                <!-- reserve-keyword -->
                                                <el-input v-model="yesEnclosureUserName" placeholder="用户名称/IMEI">
                                                </el-input>
                                                <img @click="bindEmpty(1)" class="mouse" src="../../assets/group/gb.png" alt="" />
                                            </div>
                                            <div class="search_but mouse" @click="bindSearch(1)">
                                                <img src="../../assets/group/search.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="dialog_group_user_search_tree">
                                            <!-- :render-content="renderContent" -->
                                            <el-tree @check="checkOne" :data="yesEnclosureUserArrayOne" show-checkbox
                                                node-key="User_ID" default-expand-all :expand-on-click-node="false">
                                                <div class="custom-tree-node ellipsis_app" slot-scope="{data}">
                                                    <span>{{ data.User_Name }} ({{ data.User_Account }})</span>
                                                </div>
                                            </el-tree>
                                        </div>
                                    </div>
                                </div>
                                <div style="width:40px;margin-top: 45px;" class="dialog_group_user_but">
                                    <el-tooltip content="全部移除" placement="top">
                                        <img class="mouse" src="@/assets/group/wholemove.png" @click="changeGroup(1)"
                                            alt="">
                                    </el-tooltip>
                                    <el-tooltip content="移除选中的用户" placement="top">
                                        <img class="mouse" src="@/assets/group/moveuser.png" @click="changeGroup(2)"
                                            alt="">
                                    </el-tooltip>
                                    <el-tooltip content="添加选中的用户" placement="top">
                                        <img class="mouse" src="@/assets/group/adduser.png" @click="changeGroup(3)"
                                            alt="">
                                    </el-tooltip>
                                    <el-tooltip content="全部添加" placement="top">
                                        <img class="mouse" src="@/assets/group/wholeadd.png" @click="changeGroup(4)"
                                            alt="">
                                    </el-tooltip>
                                </div>
                                <div class="dialog_group_user_xz_box">
                                    <div class="dialog_group_user_xz_box_title">不在围栏用户</div>
                                    <div class="dialog_group_user_search">
                                        <!-- 搜索 -->
                                        <div class="search search_one flex">
                                            <div class="search_input flex">
                                                <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
                                                <!-- reserve-keyword -->
                                                <el-input v-model="noEnclosureUserName" placeholder="用户名称/IMEI">
                                                </el-input>
                                                <img src="../../assets/group/gb.png" class="mouse" @click="bindEmpty(2)" alt="" />
                                            </div>
                                            <div class="search_but mouse" @click="bindSearch(2)">
                                                <img src="../../assets/group/search.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="dialog_group_user_search_tree">
                                            <!-- :render-content="renderContent" -->
                                            <el-tree @check="checkTow" :data="noEnclosureUserArrayOne" show-checkbox
                                                node-key="User_ID" default-expand-all :expand-on-click-node="false">
                                                <div class="custom-tree-node ellipsis_app" slot-scope="{data}">
                                                    <span>{{ data.User_Name }} ({{ data.User_Account }})</span>
                                                </div>
                                            </el-tree>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 结束 -->
                    </div>
                    <!-- 时间设定 -->
                    <div class="dialog_group" v-if="enclosureId == 2">
                        <div class="dialog_group_user flex_direction">
                            <div class="dialog_block dialog_text">
                                <span class="demonstration">有效日期</span>
                                <el-date-picker prefix-icon="none" v-model="effectiveDate" type="daterange"
                                    :default-value="new Date()" range-separator="至" :picker-options="pickerOptions"
                                    start-placeholder="开始日期" end-placeholder="结束日期">
                                </el-date-picker>
                                <span style="margin-left: 15px;" class="dialog_group_red">*不选择默认永久有效</span>
                            </div>
                            <div class="dialog_block dialog_text">
                                <span class="demonstration">有效时段</span>
                                <el-time-picker is-range :clearable="false" prefix-icon="none" v-model="validPeriod"
                                    range-separator="至" start-placeholder="00:00:00" end-placeholder="23:59:59"
                                    placeholder="选择时间范围">
                                </el-time-picker>
                            </div>
                            <div class="dialog_block dialog_text">
                                <!-- <el-checkbox v-model="checked" style="color: #fff;" fill="#4AFFFF" text-color="#ffffff"
                                    v-for="item in ['一','二','三','四','五','六','日']" :key="item">周{{item}}</el-checkbox> -->
                                <el-checkbox-group v-model="dayOfTheWeek" @change="sunday">
                                    <el-checkbox v-for="city in weekly" :label="city.id" :key="city.id">{{ city.name }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </div>
                    </div>
                    <!-- 时间设定结束 -->
                </div>
            </div>
            <!-- 编辑结束 -->
            <div class="bottom">
                <el-button class="bc" @click="getSendOut()">保存</el-button>
                <el-button class="gb" @click="gbDialog()">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import regfunc from "@/method/form/regfunc.js";
import axios from "axios";
import beasconfig from '@/api/beasconfig.js';
import { queryEnclosureMember, queryUNEnclosureMember } from "@/administration/electronicsEnclosure.js";
import { _debounce } from "@/method/utils.js";
const enclosureUrlapi = beasconfig.enclosureUrlapi;
export default {
    props: {
        // dialogTableVisible: {       //整个弹出框是否显示
        //     type: Boolean,
        //     default: true
        // },
        dialogTitle: {
            type: String,
            default: ''
        },
        timeShow: {      //选择时间
            type: Boolean,
            default: false
        },
        width: {     //弹出框的宽
            type: String,
            default: '960px'
        },
        bgShow: {    //编辑显示
            type: Boolean,
            default: true
        },
        editEnclosureInfo: {       //围栏数据
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            userInfo: {

            },
            pickerOptions: {
                // disabledDate(time) {
                //     return time.getTime() > Date.now();
                // }
                disabledDate(time) {
                    return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
                }
            },
            dialogTableVisible: false,
            enclosureArray: [
                {
                    id: 1,
                    name: '围栏编辑'
                },
                {
                    id: 2,
                    name: '时间设定'
                }
            ],
            enclosureId: 1,
            timeData: {
                startTime: '',       //开始时间
                endTime: ''          //结束时间
            },
            InEnclosureUserArray: [],    //再组选中
            fouEnclosureUserArray: [],    //不在组选中
            yesEnclosureUserArray: [],
            yesEnclosureUserArrayOne: [],
            yesEnclosureUserName: '',    //在组围栏用户名称
            noEnclosureUserArray: [],
            noEnclosureUserArrayOne: [],
            noEnclosureUserName: '',     //不在组围栏用户名称
            fenceName: '',//围栏名称
            showArea: '',
            prohibit: "1",  //触发方式
            pointList: '',//经纬度数据
            pointListss: '',//经纬度数据
            validStartTime: 0,
            validEndTime: 0,
            ddyId: 0,
            validPeriod: '',//有效时段
            dayOfTheWeek: [],
            effectiveDate: '',//有效日期
            weekly: [{
                id: 2,
                name: '周一'
            },
            {
                id: 3,
                name: '周二'
            },
            {
                id: 4,
                name: '周三'
            },
            {
                id: 5,
                name: '周四'
            },
            {
                id: 6,
                name: '周五'
            },
            {
                id: 7,
                name: '周六'
            },
            {
                id: 1,
                name: '周日'
            }],
            paging: {
                pageNo: 1,
                pageSize: 10,
                msgType: 1
            },
            checked: null
        };
    },
    created() {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    },
    beforeCreate: function () { },
    methods: {
        // 切换
        bindSwitchGb(id, type) {
            if (type == 2) {        //编辑显示
                this.enclosureId = id;
            }
        },
        // 打开对话框
        openPg() {
            this.dialogTableVisible = true;
            this.getFenceDetails();
            this.getQueryEnclosureMember();
            this.getQueryUNEnclosureMember();
        },
        // 关闭对话框
        gbDialog() {
            this.dialogTableVisible = false;
        },
        getSendOut: _debounce(function () {        //发送
            let that = this;
            if (!regfunc.wAgentName.test(that.fenceName)) {
                that.$MessageWarning('围栏名称由1-15位中文,数字,字母，下划线组成，且不能以下划线开头！');
                return
            }
            if (that.dayOfTheWeek.length <= 0) {
                that.$MessageWarning('请至少选择一天!');
                return
            }
            var effectiveTimes, effectiveTimes1, effectiveTimes2
            if (that.validPeriod) {
                effectiveTimes1 = that.$moment(that.validPeriod[0]).format("HH:mm:ss");
                effectiveTimes2 = that.$moment(that.validPeriod[1]).format("HH:mm:ss");
                effectiveTimes = effectiveTimes1 + '|' + effectiveTimes2;
            } else {
                effectiveTimes1 = '00:00:00';
                effectiveTimes2 = '23:59:59';
                effectiveTimes = effectiveTimes1 + '|' + effectiveTimes2;
            }
            if (that.effectiveDate) {
                that.validStartTime = that.$moment(that.effectiveDate[0]).valueOf();
                that.validEndTime = that.$moment(that.effectiveDate[1]).valueOf();
            } else {
                that.validStartTime = 0
                that.validEndTime = 0
            }
            let parmas = {
                efId: that.editEnclosureInfo.efId,
                efName: that.fenceName,
                ddyId: parseInt(that.ddyId),
                efType: that.prohibit,  //禁入禁出
                companyId: that.userInfo.User_CompanyID,
                validStartTime: that.validStartTime ? that.validStartTime : 0,//开始日期
                validEndTime: that.validEndTime ? that.validEndTime : 0, // 结束日期
                effectiveTimes: effectiveTimes, //时段
                periodData: that.dayOfTheWeek.join(','), //选中了周几
                uids: that.yesEnclosureUserArray.map(item => item.User_ID).join()
            }
            axios.post(`${enclosureUrlapi}-${this.$store.state.userState.server}/electronicFence/saveTwo`, parmas)
                .then(res => {
                    if (res.data.code == 200) {
                        // that.$store.state.toUpdateEnclosure = 2;
                        that.$MessageSuccess('保存成功！');
                        that.$emit('changeEditEnclosure', JSON.stringify(parmas), 1)
                        that.dialogTableVisible = false;
                    } else if (res.data.code == 511) {
                        that.$MessageWarning("围栏名称重复，请修改后重试!");
                    } else {
                        that.$MessageWarning(res.data.message);
                    }
                })
        }, 500),
        /*围栏详情*/
        getFenceDetails() {
            axios.get(`${enclosureUrlapi}-${this.$store.state.userState.server}/electronicFence/${this.editEnclosureInfo.efId}`)
                // axios.get(`/liang/electronicFence/${this.efId}`)
                .then(res => {
                    // var that = this;
                    var effectiveDates = [];
                    var data = res.data.data;
                    this.fenceName = data.efName; //围栏名称
                    this.showArea = data.efRemark; //是否显示
                    this.prohibit = JSON.stringify(data.efType); //禁出 禁入
                    this.ddyId = data.ddyId; //调度员id
                    this.pointList = data.pointList; //获取经纬度坐标
                    this.pointListss = data.pointList; //获取经纬度坐标
                    let [startTime, endTime] = data.effectiveTimes.split('|'); //时段
                    if (data.validStartTime != 0) {
                        this.validStartTime = this.$moment(data.validStartTime).format("YYYY-MM-DD"); //有效日期
                        this.validEndTime = this.$moment(data.validEndTime).format("YYYY-MM-DD"); //有效日期结束
                    } else {
                        this.validStartTime = '';
                        this.validEndTime = '';
                    }
                    console.warn(startTime, endTime)
                    const dateTest = this.$moment(new Date()).format('YYYY-MM-DD');
                    console.log('有时候无值', startTime, endTime)
                    if (startTime == undefined || startTime == "" || endTime == undefined || endTime == "") {
                        this.validPeriod = '';
                    } else {
                        startTime = dateTest + ' ' + startTime;
                        endTime = dateTest + ' ' + endTime;
                        this.validPeriod = [new Date(startTime), new Date(endTime)];
                    }

                    this.dayOfTheWeek = data.periodData.split(","); //星期几
                    this.dayOfTheWeek = this.dayOfTheWeek.map(function (data) {
                        return +data;
                    });
                    effectiveDates[0] = this.validStartTime;
                    effectiveDates[1] = this.validEndTime;
                    this.effectiveDate = effectiveDates;
                })
                .catch(
                    console.error
                )
        },
        getQueryEnclosureMember() {  //查询围栏成员
            let data = {
                enclosureId: this.editEnclosureInfo.efId,
                keyword: this.yesEnclosureUserName
            }
            queryEnclosureMember(data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    this.yesEnclosureUserArray = res.data.data;
                    this.yesEnclosureUserArrayOne = res.data.data;
                } else {
                    this.$MessageWarning(res.data.msg);
                }
            })
        },
        getQueryUNEnclosureMember() {   //查询非围栏成员
            let data = {
                uId: this.userInfo.id,
                enclosureId: this.editEnclosureInfo.efId,
                keyword: this.noEnclosureUserName
            }
            queryUNEnclosureMember(data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    this.noEnclosureUserArray = res.data.data;
                    this.noEnclosureUserArrayOne = res.data.data;
                } else {
                    this.$MessageWarning(res.data.msg);
                }
            })
        },
        bindSearch(type) {      //搜索
            if (type == 1) {      //搜索再组成员
                if (this.yesEnclosureUserName.trim() != '' && this.yesEnclosureUserArray.length > 0) {
                    this.yesEnclosureUserArrayOne = [];
                    this.yesEnclosureUserArray.forEach(item => {
                        if (item.User_Name.toLowerCase().indexOf(this.yesEnclosureUserName.toLowerCase()) > -1 || item.User_Account.toLowerCase().indexOf(this.yesEnclosureUserName.toLowerCase()) > -1) {
                            this.yesEnclosureUserArrayOne.push(item)
                        }
                    })
                } else {
                    this.yesEnclosureUserArrayOne = this.yesEnclosureUserArray;
                }
                // this.getQueryEnclosureMember();
            } else if (type == 2) {  //搜索不再组成员
                if (this.noEnclosureUserName.trim() != '' && this.noEnclosureUserArray.length > 0) {
                    this.noEnclosureUserArrayOne = [];
                    this.noEnclosureUserArray.forEach(item => {
                        if (item.User_Name.toLowerCase().indexOf(this.noEnclosureUserName.toLowerCase()) > -1 || item.User_Account.toLowerCase().indexOf(this.noEnclosureUserName.toLowerCase()) > -1) {
                            this.noEnclosureUserArrayOne.push(item)
                        }
                    })
                } else {
                    this.noEnclosureUserArrayOne = this.noEnclosureUserArray;
                }
                // this.getQueryUNEnclosureMember();
            }
        },
        bindEmpty(type) {        //清空输入框值
            if (type == 1) {
                this.yesEnclosureUserName = "";
            } else if (type == 2) {
                this.noEnclosureUserName = "";
            }
        },
        checkOne(data, tree) {       //在组选中
            this.InEnclosureUserArray = tree.checkedNodes;
        },
        checkTow(data, tree) {     //不在组选中
            this.fouEnclosureUserArray = tree.checkedNodes;
        },
        changeGroup(type) {      //添加 删除
            let that = this;
            // let data = {
            //     uId: that.userInfo.id,
            //     gId: that.dialogData.Gid
            // }
            let arr = [];
            // let uids;
            if (type <= 2) {
                arr = that.InEnclosureUserArray;
                if (that.yesEnclosureUserArray.length <= 0) {
                    that.$MessageWarning('移除失败，在围栏用户列表为空！');
                    return
                }
                if (type == 2 && arr.length == 0) {
                    that.$MessageWarning('请选择需要移除的用户！');
                    return
                }
                that.setEnclosureUser(type)
                // data.uids = type == 1 ? that.InEnclosureUserArray.map(item => item.User_ID).join() : arr.map(item => item.User_ID).join();
                // that.getRemoveMember(data);
            } else {
                let arr = that.fouEnclosureUserArray;
                if (that.noEnclosureUserArray.length <= 0) {
                    that.$MessageWarning('添加失败，不在围栏用户列表为空！');
                    return
                }
                if (type == 3 && arr.length == 0) {
                    that.$MessageWarning('请选择需要添加的用户！');
                    return
                }
                that.setEnclosureUser(type)
                // let array = type == 3 ? arr : that.fouEnclosureUserArray;
                // let members = [];
                // array.forEach(item => {
                //     members.push({
                //         user_id: item.User_ID,
                //         id: item.id
                //     })
                // })
                // data.members = members;
                // that.getAddMember(data);
            }
        },
        setEnclosureUser(type) { //设置围栏成员列表
            let parmas;
            let uids;
            let arr = [];
            let ids = [];
            if (type == 1) {     //删除
                // uids = '';     //移除全部传空
                // arr = [...this.InEnclosureUserArray, ...this.yesEnclosureUserArrayOne];
                // uids = arr.map(item => item.User_ID).join();
                arr = JSON.parse(JSON.stringify(this.yesEnclosureUserArray));
                this.yesEnclosureUserArray.forEach((item) => {
                    this.yesEnclosureUserArrayOne.forEach(item1 => {
                        if (item.User_ID == item1.User_ID) {
                            let i = arr.findIndex(r => r.User_ID == item1.User_ID);
                            arr.splice(i, 1);
                        }
                    })
                })
                uids = arr.map(item => item.User_ID).join();
            } else if (type == 2) {
                arr = JSON.parse(JSON.stringify(this.yesEnclosureUserArray));
                this.yesEnclosureUserArray.forEach((item) => {
                    this.InEnclosureUserArray.forEach(item1 => {
                        if (item.User_ID == item1.User_ID) {
                            let i = arr.findIndex(r => r.User_ID == item1.User_ID);
                            arr.splice(i, 1);
                        }
                    })
                })
                uids = arr.map(item => item.User_ID).join();
            } else if (type == 3) {        //添加
                this.noEnclosureUserArray.forEach(item => {
                    this.fouEnclosureUserArray.forEach(item1 => {
                        if (item.User_ID == item1.User_ID) {
                            ids.push(item)
                        }
                    })
                })
                arr = [...ids, ...this.yesEnclosureUserArray];
                uids = arr.map(item => item.User_ID).join();
            } else if (type == 4) {
                this.noEnclosureUserArray.forEach(item => {
                    this.noEnclosureUserArrayOne.forEach(item1 => {
                        if (item.User_ID == item1.User_ID) {
                            arr.push(item)
                        }
                    })
                })
                arr = [...arr, ...this.yesEnclosureUserArray];
                uids = arr.map(item => item.User_ID).join();
            }
            parmas = {
                wlId: this.editEnclosureInfo.efId,
                ddyId: this.ddyId,
                uids: uids
            }
            axios.post(`${enclosureUrlapi}-${this.$store.state.userState.server}/electronicFence/setEnclosureUser`, parmas)
                .then(res => {
                    // console.log('666', res);
                    if (res.data.code == 200) {
                        this.$emit('changeEditEnclosure', JSON.stringify({ efId: this.editEnclosureInfo.efId }), 2)
                        this.InEnclosureUserArray = [];
                        this.fouEnclosureUserArray = [];
                        if (type == 1) {
                            this.yesEnclosureUserArrayOne = [];
                            this.yesEnclosureUserArray = [];
                            this.yesEnclosureUserName = "";
                        } else if (type == 2) {
                            this.yesEnclosureUserName = "";
                        } else if (type == 3) {
                            // this.yesEnclosureUserArray = arr;
                            this.noEnclosureUserName = "";
                        } else if (type == 4) {
                            this.noEnclosureUserName = "";
                            this.noEnclosureUserArrayOne = [];
                        }
                        this.getQueryEnclosureMember();
                        this.getQueryUNEnclosureMember();
                    } else {
                        this.$MessageWarning(res.data.message);
                    }
                })
        },
        sunday() {
            console.log('周天选择', this.dayOfTheWeek)
        },
        deweight(arr) {      //数组去重
            let de_arr = []
            arr.forEach(i => {
                let isTrue = de_arr.every((j) => j.id != i.id);
                isTrue ? de_arr.push(i) : ''
            });
            return de_arr.filter(item => item.online == 3);
        },
        bindInput(value) {        //不能输入
            let reg = regfunc.wAgentName;
            let n = value.search(reg);
            if (n === -1 && value.length > 1) {
                this.fenceName = value.slice(0, n);
            } else if (n === -1 && value.length >= 1) {
                this.fenceName = value.slice(0, n);
            }
        },
        focus() {    //获取焦点
            this.$store.state.isFocusing = true;
        },
        blur() {     //失去焦点
            this.$store.state.isFocusing = false;
        }
    },
    mounted() {

    },
    filters: {
        rounding: function (duration) { //录音取整
            return Math.round(duration)
        },
        gbTime: function (data) {

            return Math.round(data / 1000);
        }
    },
    watch: {
        //监听事件
        $route() { },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
::-webkit-scrollbar {
    width: 6px;
    height: 100%;
    background-color: #ccc;
}

::-webkit-scrollbar-track {
    width: 6px;
    background-color: #4D4D4D;
}

::-webkit-scrollbar-thumb {
    background-color: #727171;
}

.box {
    width: 100vw;
    height: 100vh;
    color: #fff;

}

/deep/.el-dialog {
    background: #4D4D4D;
    margin-top: 20vh;

    &__title {
        color: #fff;
        font-size: 16px;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: #4AFFFF !important;
    }

    .dialog {
        width: 94%;
        background-color: #383838;
        margin: auto;
        color: #fff;
        border: 1px solid #6e6d6d;

        &_block {
            margin-top: 35px;

            .demonstration {
                margin-right: 20px;
            }

            .el-input--prefix .el-input__inner {
                padding-left: 15px;
            }

            // .el-icon-date:before {
            //     display: none;
            // }

            .el-range-separator {
                color: #fff;
            }

            .el-date-editor .el-range-input {
                color: #fff;
                background-color: transparent;
            }

            // .el-range-editor .el-range-input {
            //     width: 100%;
            // }

            // .el-date-editor .el-input__icon {
            //     display: none;
            // }

            .el-input__inner {
                width: 330px;
                height: 35px;
                background-color: transparent;
                border: 1px solid #6a6a6a;
                color: #f3f3f3;
                border-radius: 0px;
            }
        }

        &_text {
            font-size: 14px;
        }

        &_left {
            width: 120px;
            height: 100%;
            background-color: #4D4D4D;

            &_groupBg {
                width: 100%;
                height: 75px;
                line-height: 75px;
                text-align: center;
            }

            &_bg {
                width: 100%;
                height: 120px;
                line-height: 120px;
                text-align: center;
            }

            &_bgOne {
                background-color: #383838;
            }
        }

        &_right {
            flex: 1;
            height: 100%;
            margin: auto;

            &_top {
                width: 90%;
                margin: 15px auto 25px auto;
                height: 340px;
                overflow: auto;
                border: 1px solid #6e6d6d;

                &_dh {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 97%;
                    margin: 15px auto;

                    &_left {
                        border-style: solid;
                        border-color: transparent;
                        border-width: 6px 8px 6px 0px;
                        border-right-color: #2e7777;
                    }

                    &_right {
                        // flex: 1;
                        width: 80%;
                        min-height: 75px;
                        background-color: #2e7777;
                        border-radius: 10px;
                        font-size: 14px;

                        &_text {
                            padding: 15px 10px;
                            line-height: 25px;
                        }
                    }

                    &_rightOne {
                        width: 149px;
                        min-height: 55px;
                        background-color: #2e7777;
                        border-radius: 10px;
                        font-size: 14px;

                        img {
                            width: 15px;
                            height: 21px;
                            margin-left: 15px;
                        }

                        span {
                            margin-left: 10px;
                        }
                    }

                    &_text {
                        // position: absolute;
                        // left: calc(80% + 16px);
                        // bottom: 0px;
                        color: #727171;
                        margin-top: 35px;
                        margin-left: 10px;
                    }
                }
            }

            &_bottom {
                width: 90%;
                margin: auto;
                height: 85px;
                border: 1px solid #6e6d6d;

                .el-textarea__inner {
                    // width: 100%;
                    padding: 15px !important;
                    // height: 85px;
                    background-color: transparent;
                    color: #fff;
                    border: none;
                    font-size: 14px;
                    resize: none;
                }
            }

            &_ly {
                width: 90%;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_img {
                width: 302px;
                height: 66px;
                // margin: auto;
                background: url("../../assets/gb/lyaj.png");
                background-size: 100% 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_delet {
                width: 88px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background-color: #2e7777;
                opacity: 0.9;
                border: 1px solid #30aeae;
                color: #4AFFFF;
                margin-left: 35px;
            }
        }

        // 群组编辑
        &_group {
            width: 92%;
            margin: auto;

            &_top {
                width: 100%;
                height: 75px;
                border-bottom: 1px solid #6e6d6d;

                .el-input__inner {
                    width: 100%;
                    height: 40px;
                    background-color: #4D4D4D;
                    border: 1px solid #4D4D4D;
                    color: #fff;
                    border-radius: 0px !important;
                }
            }

            &_red {
                font-size: 12px;
                color: red;
            }

            &_input {
                width: 285px;
                height: 40px;
                margin: 0px 10px;
            }

            &_user {
                width: 100%;
                height: 370px;

                &_text {
                    margin-right: 15px;
                }

                &_xz {
                    flex: 1;
                    display: flex;

                    &_box {
                        width: 280px;
                        height: 310px;

                        &_title {
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            color: #4AFFFF;
                        }
                    }
                }

                &_but {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        width: 23px;
                        height: 19px;
                        margin-top: 25px;
                    }
                }

                &_search {
                    width: 100%;
                    height: 100%;
                    border: 1px solid #6e6d6d;

                    &_tree {
                        width: 100%;
                        height: calc(100% - 45px);
                        overflow: auto;
                        margin-top: 10px;
                    }
                }

                &_gl {
                    flex: 1;
                    display: flex;
                    margin-top: 20px;

                    &_box {
                        width: 215px;
                        text-align: center;

                        &_title {
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            color: #4AFFFF;
                        }

                        &_content {
                            width: 100%;
                            height: 22px;
                            line-height: 22px;
                        }

                        &_contentOne {
                            border: 1px solid #6e6d6d;
                            border-radius: 2px;
                        }
                    }
                }
            }

            &_delet {
                width: 108px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background-color: #2e7777;
                opacity: 0.9;
                border: 1px solid #30aeae;
                color: var(--main-color);
                margin-left: 10px;
            }
        }
    }

}

.dialog_group_user_gl_box_content /deep/.el-input__inner {
    height: 22px !important;
    line-height: 22px !important;
    background-color: transparent;
    border: none;
    color: #fff;
    text-align: center;
}

.dialog_group_user_gl_box_content /deep/.el-select .el-input .el-select__caret {
    color: #4AFFFF;
}

.dialog_group_user_gl_box_content /deep/.el-input__icon {
    line-height: 25px;
}

.search_one {
    /deep/.el-input__inner {
        width: 100%;
        // width: 100%;
        height: 28px;
        line-height: 28px;
        background-color: #333333;
        color: #ffffff;
        border: none;
        border-radius: 0px;
        padding: 0px 10px;
    }
}

.search_tow {
    width: 415px;
    height: 45px;
    border: 1px solid #2e7777;
    background-color: #383838 !important;
    border-radius: 2px;

    /deep/.el-input__inner {
        width: 100%;
        // width: 100%;
        height: 45px !important;
        line-height: 45px !important;
        background-color: #383838 !important;
        color: #ffffff;
        border: none !important;
        border-radius: 0px;
        padding: 0px 10px;
    }

    &_input {
        flex: 1;
        height: 100%;
        background-color: #383838;
        color: #ffffff !important;

        img {
            width: 12px;
            height: 12px;
            margin: 0px 15px;
        }
    }

    &_but {
        width: 55px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background-color: #3b7777;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 17px;
            height: 17px;
        }
    }
}

.search {
    width: 100%;
    height: 28px;
    background-color: #3b7777;
    border: 1px solid #3b7777;

    &_input {
        flex: 1;
        height: 100%;
        background-color: #333333;
        color: #ffffff !important;


        img {
            width: 12px;
            height: 12px;
            margin: 0px 15px;
        }
    }

    &_but {
        width: 40px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background-color: #3b7777;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 17px;
            height: 17px;
        }
    }
}


.bottom {
    width: 100%;
    text-align: center;
    padding: 15px 0px;

    /deep/.gb.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid #fff !important;
        background-color: transparent !important;
        color: #fff !important;
    }

    /deep/.bc.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid #4AFFFF !important;
        background-color: transparent !important;
        color: #4AFFFF !important;
        margin-right: 30px;
    }
}


/deep/.el-tree {
    background-color: transparent;
    color: #fff;

    .el-icon-caret-right:before {
        content: "";
    }

    .el-tree-node__content:hover,
    .el-upload-list__item:hover {
        background-color: transparent !important;
    }

    .el-tree-node:focus,
    .is-expanded:focus,
    .is-focusable:focus {
        background-color: transparent !important;
    }

    .el-tree-node.is-current>.el-tree-node__content {
        background: transparent !important;
    }

    .el-tree-node__expand-icon.expanded {
        color: var(--main-color);
    }

    .el-checkbox__inner {
        background-color: #343434 !important;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner,
    .el-checkbox__inner:hover {
        border-color: #dcdfe6;
    }

    .el-checkbox__inner::after {
        border-color: var(--main-color);
    }

    .el-tree-node {
        width: 92%;
        margin: auto;
    }

    .el-tree-node:focus>.el-tree-node__content {
        background: transparent !important;
    }

    .el-tree-node__content>.el-tree-node__expand-icon {
        padding: 0px;
    }

    .el-tree-node__content {
        height: 30px;
        line-height: 30px;
    }

    .el-tree-node__label {
        width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/deep/.el-dialog--center .el-dialog__body {
    padding: 0px !important;
}

/deep/.el-checkbox__label {
    color: #fff !important;
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: var(--main-color);
    border-color: var(--main-color);
}

/deep/.el-checkbox__inner::after {
    border: 1px solid #000;
    border-left: 0;
    border-top: 0;
}

.flex {
    display: flex;
    align-items: center;
}

.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_direction {
    //垂直居中
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
}

.custom-tree-node {
    width: calc(100% - 30px);
}

/deep/.el-radio {
    color: #fff;

    .el-radio__input.is-checked .el-radio__inner {
        border-color: #ffffff;
        background: #000000;
    }

    .el-radio__input.is-checked+.el-radio__label {
        color: #fff;
    }

    .el-radio__inner::after {
        width: 8px;
        height: 8px;
        background-color: var(--main-color);
    }
}
</style>