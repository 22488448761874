<template>
    <div class="box">
        <div class="nav mouse prohibitCopy">
            <div :class="['nav_container', checkRecord(item) ? 'bg_tow' : 'bg_one']" v-for="(item, index) in leftData"
                :key="index" @click="tabLeft(item, index)">
                <img :src="checkRecord(item) ? item.img : item.xzImg" alt="">
                {{ item.name }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            leftData: [
                // {
                //     id: 1,
                //     name: '录音记录',
                //     xzImg: require("@/assets/history/lyjl1.png"),
                //     img: require("@/assets/history/lyjl.png"),
                //     router: '/history/recording',
                //     routerName: ['recording']
                // },
                {
                    id: 2,
                    name: this.$t('message.SOSrecord'),
                    xzImg: require("@/assets/history/sos1.png"),
                    img: require("@/assets/history/sos.png"),
                    router: '/history/sosrecord',
                    routerName: ['sosrecord', 'sosPosition']
                },
                // {
                //     id: 3,
                //     name: '视频记录',
                //     xzImg: require("@/assets/history/spjl1.png"),
                //     img: require("@/assets/history/spjl.png"),
                //     router: '/history/videorecord',
                //     routerName: ['videorecord']
                // },
                // {
                //     id: 4,
                //     name: '广播记录',
                //     xzImg: require("@/assets/history/gb1.png"),
                //     img: require("@/assets/history/gb.png"),
                //     router: '/history/radiobroadcast',
                //     routerName: ['radiobroadcast']
                // }
            ],
            leftIndex: 0
        };
    },
    created() {

    },
    beforeCreate: function () { },
    mounted() {

    },
    methods: {
        tabLeft(row, index) {
            this.leftIndex = index;
            this.$router.push(row.router);
            // this.$emit('sidebarTab',index);
        },
        checkRecord(row) {
            let bool = row.routerName.some(item => item == this.$route.name);

            return bool
        }
    },
    watch: {
        //监听事件
        $route() { },
    },
};
</script>

<style scoped lang="less">
.box {
    width: 100%;
    height: 100%;
    background: url("../../assets/group/leftBg1.png");
    background-size: 100% 100%;

    .nav {
        width: 98%;
        // margin: auto;
        position: relative;
        top: 5px;

        &_container {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: 100% 100%;
            font-size: 15px;

            img {
                width: 22px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }
}

.bg_one {
    color: #ffffff;
    background: url("../../assets/history/select.png");
}

.bg_tow {
    color: #000000;
    background: url("../../assets/history/select1.png");
}

.baidu-api {
    width: 100%;
    height: 100%;
}

.flex {
    display: flex;
    align-items: center;
}
</style>