import { render, staticRenderFns } from "./addselfgroup.vue?vue&type=template&id=cf53befa&scoped=true&"
import script from "./addselfgroup.vue?vue&type=script&lang=js&"
export * from "./addselfgroup.vue?vue&type=script&lang=js&"
import style0 from "./addselfgroup.vue?vue&type=style&index=0&id=cf53befa&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf53befa",
  null
  
)

export default component.exports